import React from "react";
import "./index.scoped.css";
export default function BallByBall() {
  return (
    <app-casino-match-detail
      _nghost-ng-c3502172659
      className="ng-tns-c3502172659-8 ng-star-inserted"
    >
      <div
        _ngcontent-ng-c3502172659
        className="main-container ng-tns-c3502172659-8"
      >
        <app-side-bar
          _ngcontent-ng-c3502172659
          className="ng-tns-c3502172659-8"
          _nghost-ng-c3464377771
        >
          <div _ngcontent-ng-c3464377771 className="sidebar left-sidebar">
            <div _ngcontent-ng-c3464377771 className="accordion">
              <div _ngcontent-ng-c3464377771 className="accordion-item">
                <h2
                  _ngcontent-ng-c3464377771
                  className="sidebar-title accordion-header"
                >
                  <button
                    _ngcontent-ng-c3464377771
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsetwo"
                    aria-expanded="true"
                    aria-controls="collapsetwo"
                    className="accordion-button"
                  >
                    Others{" "}
                    <svg _ngcontent-ng-c3464377771 className="icon arrowdown">
                      <use _ngcontent-ng-c3464377771 xlinkHref="#arrowdown" />
                    </svg>
                    <svg _ngcontent-ng-c3464377771 className="icon arrowup">
                      <use _ngcontent-ng-c3464377771 xlinkHref="#arrowup" />
                    </svg>
                  </button>
                </h2>
                <div
                  _ngcontent-ng-c3464377771
                  id="collapsetwo"
                  className="accordion-collapse collapse show"
                >
                  <div
                    _ngcontent-ng-c3464377771
                    className="other-casino-list accordion-body"
                  >
                    <ul _ngcontent-ng-c3464377771 className="ng-star-inserted">
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item ng-star-inserted"
                      >
                        <a _ngcontent-ng-c3464377771 className="nav-link">
                          <span _ngcontent-ng-c3464377771 className="blink_me">
                            32 Card Casino
                          </span>
                        </a>
                      </li>
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item ng-star-inserted"
                      >
                        <a _ngcontent-ng-c3464377771 className="nav-link">
                          <span _ngcontent-ng-c3464377771>Dragon Tiger</span>
                        </a>
                      </li>
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item ng-star-inserted"
                      >
                        <a _ngcontent-ng-c3464377771 className="nav-link">
                          <span _ngcontent-ng-c3464377771>
                            Bollywood Casino
                          </span>
                        </a>
                      </li>
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item ng-star-inserted"
                      >
                        <a _ngcontent-ng-c3464377771 className="nav-link">
                          <span _ngcontent-ng-c3464377771 className="blink_me">
                            Worli
                          </span>
                        </a>
                      </li>
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item ng-star-inserted"
                      >
                        <a _ngcontent-ng-c3464377771 className="nav-link">
                          <span _ngcontent-ng-c3464377771>Race 20-20</span>
                        </a>
                      </li>
                      {/**/}
                      {/**/}
                      {/**/}
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item ng-star-inserted"
                      >
                        <a _ngcontent-ng-c3464377771 className="nav-link">
                          <span _ngcontent-ng-c3464377771>Sport Casino</span>
                        </a>
                      </li>
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item ng-star-inserted"
                      >
                        <a _ngcontent-ng-c3464377771 className="nav-link">
                          <span _ngcontent-ng-c3464377771>Dart</span>
                        </a>
                      </li>
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item ng-star-inserted"
                      >
                        <a _ngcontent-ng-c3464377771 className="nav-link">
                          <span _ngcontent-ng-c3464377771>Casino War</span>
                        </a>
                      </li>
                      {/**/}
                      {/**/}
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item ng-star-inserted"
                      >
                        <a _ngcontent-ng-c3464377771 className="nav-link">
                          <span _ngcontent-ng-c3464377771>
                            3 Cards Judgement
                          </span>
                        </a>
                      </li>
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item ng-star-inserted"
                      >
                        <a _ngcontent-ng-c3464377771 className="nav-link">
                          <span _ngcontent-ng-c3464377771>Lottery</span>
                        </a>
                      </li>
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item ng-star-inserted"
                      >
                        <a _ngcontent-ng-c3464377771 className="nav-link">
                          <span _ngcontent-ng-c3464377771>Binary</span>
                        </a>
                      </li>
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item ng-star-inserted"
                      >
                        <a _ngcontent-ng-c3464377771 className="nav-link">
                          <span _ngcontent-ng-c3464377771>Virtual Sports</span>
                        </a>
                      </li>
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item ng-star-inserted"
                      >
                        <a _ngcontent-ng-c3464377771 className="nav-link">
                          <span _ngcontent-ng-c3464377771>Slot Game</span>
                        </a>
                      </li>
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item ng-star-inserted"
                      >
                        <a _ngcontent-ng-c3464377771 className="nav-link">
                          <span _ngcontent-ng-c3464377771>Cricket Casino</span>
                        </a>
                      </li>
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item ng-star-inserted"
                      >
                        <a _ngcontent-ng-c3464377771 className="nav-link">
                          <span _ngcontent-ng-c3464377771>Baccarat</span>
                        </a>
                      </li>
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                    </ul>
                    {/**/}
                  </div>
                </div>
              </div>
            </div>
            <div _ngcontent-ng-c3464377771 className="accordion">
              <div _ngcontent-ng-c3464377771 className="accordion-item">
                <h2
                  _ngcontent-ng-c3464377771
                  className="sidebar-title accordion-header"
                >
                  <button
                    _ngcontent-ng-c3464377771
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse3"
                    aria-expanded="true"
                    aria-controls="collapse3"
                    className="accordion-button"
                  >
                    All Sports{" "}
                    <svg _ngcontent-ng-c3464377771 className="icon arrowdown">
                      <use _ngcontent-ng-c3464377771 xlinkHref="#arrowdown" />
                    </svg>
                    <svg _ngcontent-ng-c3464377771 className="icon arrowup">
                      <use _ngcontent-ng-c3464377771 xlinkHref="#arrowup" />
                    </svg>
                  </button>
                </h2>
                <div
                  _ngcontent-ng-c3464377771
                  id="collapse3"
                  className="accordion-collapse collapse show"
                >
                  <div
                    _ngcontent-ng-c3464377771
                    className="menu-box accordion-body"
                  >
                    <ul
                      _ngcontent-ng-c3464377771
                      className="navbar-nav ng-star-inserted"
                    >
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item dropdown"
                      >
                        <a
                          _ngcontent-ng-c3464377771
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                          className="dropdown-toggle nav-link"
                        >
                          <i
                            _ngcontent-ng-c3464377771
                            className="fa-plus-square far me-1"
                          />
                          <span _ngcontent-ng-c3464377771>Tennis</span>
                        </a>
                        {/**/}
                      </li>
                    </ul>
                    {/**/}
                    {/**/}
                    <ul
                      _ngcontent-ng-c3464377771
                      className="navbar-nav ng-star-inserted"
                    >
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item dropdown"
                      >
                        <a
                          _ngcontent-ng-c3464377771
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                          className="dropdown-toggle nav-link"
                        >
                          <i
                            _ngcontent-ng-c3464377771
                            className="fa-plus-square far me-1"
                          />
                          <span _ngcontent-ng-c3464377771>Football</span>
                        </a>
                        {/**/}
                      </li>
                    </ul>
                    {/**/}
                    {/**/}
                    <ul
                      _ngcontent-ng-c3464377771
                      className="navbar-nav ng-star-inserted"
                    >
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item dropdown"
                      >
                        <a
                          _ngcontent-ng-c3464377771
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                          className="dropdown-toggle nav-link"
                        >
                          <i
                            _ngcontent-ng-c3464377771
                            className="fa-plus-square far me-1"
                          />
                          <span _ngcontent-ng-c3464377771>Cricket</span>
                        </a>
                        {/**/}
                      </li>
                    </ul>
                    {/**/}
                    {/**/}
                    <ul
                      _ngcontent-ng-c3464377771
                      className="navbar-nav ng-star-inserted"
                    >
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item dropdown"
                      >
                        <a
                          _ngcontent-ng-c3464377771
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                          className="dropdown-toggle nav-link"
                        >
                          <i
                            _ngcontent-ng-c3464377771
                            className="fa-plus-square far me-1"
                          />
                          <span _ngcontent-ng-c3464377771>Horse Racing</span>
                        </a>
                        {/**/}
                      </li>
                    </ul>
                    {/**/}
                    {/**/}
                    <ul
                      _ngcontent-ng-c3464377771
                      className="navbar-nav ng-star-inserted"
                    >
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item dropdown"
                      >
                        <a
                          _ngcontent-ng-c3464377771
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                          className="dropdown-toggle nav-link"
                        >
                          <i
                            _ngcontent-ng-c3464377771
                            className="fa-plus-square far me-1"
                          />
                          <span _ngcontent-ng-c3464377771>
                            Greyhound Racing
                          </span>
                        </a>
                        {/**/}
                      </li>
                    </ul>
                    {/**/}
                    {/**/}
                    <ul
                      _ngcontent-ng-c3464377771
                      className="navbar-nav ng-star-inserted"
                    >
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item dropdown"
                      >
                        <a
                          _ngcontent-ng-c3464377771
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                          className="dropdown-toggle nav-link"
                        >
                          <i
                            _ngcontent-ng-c3464377771
                            className="fa-plus-square far me-1"
                          />
                          <span _ngcontent-ng-c3464377771>Winter Sports</span>
                        </a>
                        {/**/}
                      </li>
                    </ul>
                    {/**/}
                    {/**/}
                    <ul
                      _ngcontent-ng-c3464377771
                      className="navbar-nav ng-star-inserted"
                    >
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item dropdown"
                      >
                        <a
                          _ngcontent-ng-c3464377771
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                          className="dropdown-toggle nav-link"
                        >
                          <i
                            _ngcontent-ng-c3464377771
                            className="fa-plus-square far me-1"
                          />
                          <span _ngcontent-ng-c3464377771>Election</span>
                        </a>
                        {/**/}
                      </li>
                    </ul>
                    {/**/}
                    {/**/}
                    <ul
                      _ngcontent-ng-c3464377771
                      className="navbar-nav ng-star-inserted"
                    >
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item dropdown"
                      >
                        <a
                          _ngcontent-ng-c3464377771
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                          className="dropdown-toggle nav-link"
                        >
                          <i
                            _ngcontent-ng-c3464377771
                            className="fa-plus-square far me-1"
                          />
                          <span _ngcontent-ng-c3464377771>Politics</span>
                        </a>
                        {/**/}
                      </li>
                    </ul>
                    {/**/}
                    {/**/}
                    <ul
                      _ngcontent-ng-c3464377771
                      className="navbar-nav ng-star-inserted"
                    >
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item dropdown"
                      >
                        <a
                          _ngcontent-ng-c3464377771
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                          className="dropdown-toggle nav-link"
                        >
                          <i
                            _ngcontent-ng-c3464377771
                            className="fa-plus-square far me-1"
                          />
                          <span _ngcontent-ng-c3464377771>Rugby Union</span>
                        </a>
                        {/**/}
                      </li>
                    </ul>
                    {/**/}
                    {/**/}
                    <ul
                      _ngcontent-ng-c3464377771
                      className="navbar-nav ng-star-inserted"
                    >
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item dropdown"
                      >
                        <a
                          _ngcontent-ng-c3464377771
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                          className="dropdown-toggle nav-link"
                        >
                          <i
                            _ngcontent-ng-c3464377771
                            className="fa-plus-square far me-1"
                          />
                          <span _ngcontent-ng-c3464377771>
                            Australian Rules
                          </span>
                        </a>
                        {/**/}
                      </li>
                    </ul>
                    {/**/}
                    {/**/}
                    <ul
                      _ngcontent-ng-c3464377771
                      className="navbar-nav ng-star-inserted"
                    >
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item dropdown"
                      >
                        <a
                          _ngcontent-ng-c3464377771
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                          className="dropdown-toggle nav-link"
                        >
                          <i
                            _ngcontent-ng-c3464377771
                            className="fa-plus-square far me-1"
                          />
                          <span _ngcontent-ng-c3464377771>
                            American Football
                          </span>
                        </a>
                        {/**/}
                      </li>
                    </ul>
                    {/**/}
                    {/**/}
                    <ul
                      _ngcontent-ng-c3464377771
                      className="navbar-nav ng-star-inserted"
                    >
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item dropdown"
                      >
                        <a
                          _ngcontent-ng-c3464377771
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                          className="dropdown-toggle nav-link"
                        >
                          <i
                            _ngcontent-ng-c3464377771
                            className="fa-plus-square far me-1"
                          />
                          <span _ngcontent-ng-c3464377771>Gaelic Games</span>
                        </a>
                        {/**/}
                      </li>
                    </ul>
                    {/**/}
                    {/**/}
                    <ul
                      _ngcontent-ng-c3464377771
                      className="navbar-nav ng-star-inserted"
                    >
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item dropdown"
                      >
                        <a
                          _ngcontent-ng-c3464377771
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                          className="dropdown-toggle nav-link"
                        >
                          <i
                            _ngcontent-ng-c3464377771
                            className="fa-plus-square far me-1"
                          />
                          <span _ngcontent-ng-c3464377771>Cycling</span>
                        </a>
                        {/**/}
                      </li>
                    </ul>
                    {/**/}
                    {/**/}
                    <ul
                      _ngcontent-ng-c3464377771
                      className="navbar-nav ng-star-inserted"
                    >
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item dropdown"
                      >
                        <a
                          _ngcontent-ng-c3464377771
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                          className="dropdown-toggle nav-link"
                        >
                          <i
                            _ngcontent-ng-c3464377771
                            className="fa-plus-square far me-1"
                          />
                          <span _ngcontent-ng-c3464377771>Rugby League</span>
                        </a>
                        {/**/}
                      </li>
                    </ul>
                    {/**/}
                    {/**/}
                    <ul
                      _ngcontent-ng-c3464377771
                      className="navbar-nav ng-star-inserted"
                    >
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item dropdown"
                      >
                        <a
                          _ngcontent-ng-c3464377771
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                          className="dropdown-toggle nav-link"
                        >
                          <i
                            _ngcontent-ng-c3464377771
                            className="fa-plus-square far me-1"
                          />
                          <span _ngcontent-ng-c3464377771>Golf</span>
                        </a>
                        {/**/}
                      </li>
                    </ul>
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    <ul
                      _ngcontent-ng-c3464377771
                      className="navbar-nav ng-star-inserted"
                    >
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item dropdown"
                      >
                        <a
                          _ngcontent-ng-c3464377771
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                          className="dropdown-toggle nav-link"
                        >
                          <i
                            _ngcontent-ng-c3464377771
                            className="fa-plus-square far me-1"
                          />
                          <span _ngcontent-ng-c3464377771>Baseball</span>
                        </a>
                        {/**/}
                      </li>
                    </ul>
                    {/**/}
                    {/**/}
                    <ul
                      _ngcontent-ng-c3464377771
                      className="navbar-nav ng-star-inserted"
                    >
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item dropdown"
                      >
                        <a
                          _ngcontent-ng-c3464377771
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                          className="dropdown-toggle nav-link"
                        >
                          <i
                            _ngcontent-ng-c3464377771
                            className="fa-plus-square far me-1"
                          />
                          <span _ngcontent-ng-c3464377771>Snooker</span>
                        </a>
                        {/**/}
                      </li>
                    </ul>
                    {/**/}
                    {/**/}
                    <ul
                      _ngcontent-ng-c3464377771
                      className="navbar-nav ng-star-inserted"
                    >
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item dropdown"
                      >
                        <a
                          _ngcontent-ng-c3464377771
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                          className="dropdown-toggle nav-link"
                        >
                          <i
                            _ngcontent-ng-c3464377771
                            className="fa-plus-square far me-1"
                          />
                          <span _ngcontent-ng-c3464377771>
                            Mixed Martial Arts
                          </span>
                        </a>
                        {/**/}
                      </li>
                    </ul>
                    {/**/}
                    {/**/}
                    <ul
                      _ngcontent-ng-c3464377771
                      className="navbar-nav ng-star-inserted"
                    >
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item dropdown"
                      >
                        <a
                          _ngcontent-ng-c3464377771
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                          className="dropdown-toggle nav-link"
                        >
                          <i
                            _ngcontent-ng-c3464377771
                            className="fa-plus-square far me-1"
                          />
                          <span _ngcontent-ng-c3464377771>Darts</span>
                        </a>
                        {/**/}
                      </li>
                    </ul>
                    {/**/}
                    {/**/}
                    <ul
                      _ngcontent-ng-c3464377771
                      className="navbar-nav ng-star-inserted"
                    >
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item dropdown"
                      >
                        <a
                          _ngcontent-ng-c3464377771
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                          className="dropdown-toggle nav-link"
                        >
                          <i
                            _ngcontent-ng-c3464377771
                            className="fa-plus-square far me-1"
                          />
                          <span _ngcontent-ng-c3464377771>Ice Hockey</span>
                        </a>
                        {/**/}
                      </li>
                    </ul>
                    {/**/}
                    {/**/}
                    <ul
                      _ngcontent-ng-c3464377771
                      className="navbar-nav ng-star-inserted"
                    >
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item dropdown"
                      >
                        <a
                          _ngcontent-ng-c3464377771
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                          className="dropdown-toggle nav-link"
                        >
                          <i
                            _ngcontent-ng-c3464377771
                            className="fa-plus-square far me-1"
                          />
                          <span _ngcontent-ng-c3464377771>Boxing</span>
                        </a>
                        {/**/}
                      </li>
                    </ul>
                    {/**/}
                    {/**/}
                    <ul
                      _ngcontent-ng-c3464377771
                      className="navbar-nav ng-star-inserted"
                    >
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item dropdown"
                      >
                        <a
                          _ngcontent-ng-c3464377771
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                          className="dropdown-toggle nav-link"
                        >
                          <i
                            _ngcontent-ng-c3464377771
                            className="fa-plus-square far me-1"
                          />
                          <span _ngcontent-ng-c3464377771>Motor Sport</span>
                        </a>
                        {/**/}
                      </li>
                    </ul>
                    {/**/}
                    {/**/}
                    <ul
                      _ngcontent-ng-c3464377771
                      className="navbar-nav ng-star-inserted"
                    >
                      <li
                        _ngcontent-ng-c3464377771
                        className="nav-item dropdown"
                      >
                        <a
                          _ngcontent-ng-c3464377771
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                          className="dropdown-toggle nav-link"
                        >
                          <i
                            _ngcontent-ng-c3464377771
                            className="fa-plus-square far me-1"
                          />
                          <span _ngcontent-ng-c3464377771>Esports</span>
                        </a>
                        {/**/}
                      </li>
                    </ul>
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </app-side-bar>
        <div
          _ngcontent-ng-c3502172659
          className="center-main-container casino-page ng-tns-c3502172659-8 ng-star-inserted"
        >
          <div
            _ngcontent-ng-c3502172659
            className="center-container ng-tns-c3502172659-8"
          >
            <div
              _ngcontent-ng-c3502172659
              className="casino-page-container ng-tns-c3502172659-8"
            >
              <div
                _ngcontent-ng-c3502172659
                className="casino-title-header-mobile d-xl-none ng-tns-c3502172659-8"
              >
                <ul
                  _ngcontent-ng-c3502172659
                  className="nav nav-tabs menu-tabs ng-tns-c3502172659-8"
                >
                  <li
                    _ngcontent-ng-c3502172659
                    className="nav-item ng-tns-c3502172659-8"
                  >
                    <div
                      _ngcontent-ng-c3502172659
                      className="ng-tns-c3502172659-8 active nav-link"
                    >
                      Game
                    </div>
                  </li>
                  <li
                    _ngcontent-ng-c3502172659
                    className="nav-item ng-tns-c3502172659-8"
                  >
                    <div
                      _ngcontent-ng-c3502172659
                      className="ng-tns-c3502172659-8 nav-link"
                    >
                      {" "}
                      Placed Bet (0){" "}
                    </div>
                  </li>
                </ul>
                <div
                  _ngcontent-ng-c3502172659
                  className="pe-2 ng-tns-c3502172659-8"
                >
                  <span
                    _ngcontent-ng-c3502172659
                    className="casino-rid ng-tns-c3502172659-8"
                  >
                    <a
                      _ngcontent-ng-c3502172659
                      className="d-block ng-tns-c3502172659-8"
                    >
                      <small
                        _ngcontent-ng-c3502172659
                        className="ng-tns-c3502172659-8"
                      >
                        Rules
                      </small>
                    </a>{" "}
                    Round ID:{" "}
                    <span
                      _ngcontent-ng-c3502172659
                      className="ng-tns-c3502172659-8 ng-star-inserted"
                    >
                      6850128692521
                    </span>
                    {/**/}
                  </span>
                </div>
              </div>
              <div
                _ngcontent-ng-c3502172659
                className="casino-header ng-tns-c3502172659-8"
              >
                <span
                  _ngcontent-ng-c3502172659
                  className="casino-name ng-tns-c3502172659-8"
                >
                  {" "}
                  Ball by Ball
                  <a
                    _ngcontent-ng-c3502172659
                    className="ms-1 d-xl-inline d-none ng-tns-c3502172659-8"
                  >
                    <small
                      _ngcontent-ng-c3502172659
                      className="ng-tns-c3502172659-8"
                    >
                      Rules
                    </small>
                  </a>
                </span>
                <span
                  _ngcontent-ng-c3502172659
                  className="casino-rid d-none d-xl-inline-block ng-tns-c3502172659-8"
                >
                  <small
                    _ngcontent-ng-c3502172659
                    className="ng-tns-c3502172659-8"
                  >
                    Round ID:{" "}
                    <span
                      _ngcontent-ng-c3502172659
                      className="ng-tns-c3502172659-8 ng-star-inserted"
                    >
                      6850128692521
                    </span>
                    {/**/}
                  </small>
                  <span
                    _ngcontent-ng-c3502172659
                    className="ng-tns-c3502172659-8 ng-star-inserted"
                  >
                    {" "}
                    | Min: 0 {/**/}
                    {/**/} | Max: 0 {/**/}
                    {/**/}
                  </span>
                  {/**/}
                </span>
              </div>
              {/**/}
              {/**/}
              <div
                _ngcontent-ng-c3502172659
                className="ng-tns-c3502172659-8 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c3502172659
                  className="casino-video ng-tns-c3502172659-8"
                >
                  <div
                    _ngcontent-ng-c3502172659
                    className="video-box-container ng-tns-c3502172659-8"
                  >
                    <div
                      _ngcontent-ng-c3502172659
                      className="casino-video-box ng-tns-c3502172659-8 ng-star-inserted"
                    >
                      <iframe
                        _ngcontent-ng-c3502172659
                        className="ng-tns-c3502172659-8"
                        src="https://alpha-n.qnsports.live/route/?id=3061"
                      />
                    </div>
                    {/**/}
                  </div>
                  <div
                    _ngcontent-ng-c3502172659
                    className="ng-tns-c3502172659-8 ng-star-inserted"
                  >
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                  </div>
                  {/**/}
                  <div
                    _ngcontent-ng-c3502172659
                    className="clock ng-tns-c3502172659-8"
                  >
                    <div
                      _ngcontent-ng-c3502172659
                      className="count-no ng-tns-c3502172659-8 ng-star-inserted"
                    >
                      <span
                        _ngcontent-ng-c3502172659
                        className="ng-tns-c3502172659-8"
                      >
                        <div
                          _ngcontent-ng-c3502172659
                          className="content ng-tns-c3502172659-8"
                        >
                          <div
                            _ngcontent-ng-c3502172659
                            style={{ position: "absolute" }}
                            className="ng-tns-c3502172659-8"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              className="flip ng-tns-c3502172659-8"
                            >
                              <div
                                _ngcontent-ng-c3502172659
                                className="up ng-tns-c3502172659-8"
                              >
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="ng-tns-c3502172659-8"
                                >
                                  8
                                </div>
                              </div>
                              <div
                                _ngcontent-ng-c3502172659
                                className="down ng-tns-c3502172659-8"
                              >
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="ng-tns-c3502172659-8"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="ng-tns-c3502172659-8"
                                  >
                                    8
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            style={{ position: "absolute" }}
                            className="ng-tns-c3502172659-8"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              className="flip-card ng-tns-c3502172659-8"
                            >
                              <div
                                _ngcontent-ng-c3502172659
                                className="flip-card-inner ng-tns-c3502172659-8 ng-trigger ng-trigger-flip ng-animating"
                              >
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="flip-card-front ng-tns-c3502172659-8"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="up ng-tns-c3502172659-8"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="ng-tns-c3502172659-8"
                                    >
                                      8
                                    </div>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="flip-card-back ng-tns-c3502172659-8"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="down ng-tns-c3502172659-8"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="ng-tns-c3502172659-8"
                                    >
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="ng-tns-c3502172659-8"
                                      >
                                        8
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </span>
                    </div>
                    {/**/}
                    <div
                      _ngcontent-ng-c3502172659
                      className="count-no ng-tns-c3502172659-8 ng-star-inserted"
                      style={{ marginRight: "80px" }}
                    >
                      <span
                        _ngcontent-ng-c3502172659
                        className="ng-tns-c3502172659-8"
                      >
                        <div
                          _ngcontent-ng-c3502172659
                          className="content ng-tns-c3502172659-8"
                        >
                          <div
                            _ngcontent-ng-c3502172659
                            style={{ position: "absolute" }}
                            className="ng-tns-c3502172659-8"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              className="flip ng-tns-c3502172659-8"
                            >
                              <div
                                _ngcontent-ng-c3502172659
                                className="up ng-tns-c3502172659-8"
                              >
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="ng-tns-c3502172659-8"
                                >
                                  1
                                </div>
                              </div>
                              <div
                                _ngcontent-ng-c3502172659
                                className="down ng-tns-c3502172659-8"
                              >
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="ng-tns-c3502172659-8"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="ng-tns-c3502172659-8"
                                  >
                                    1
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            style={{ position: "absolute" }}
                            className="ng-tns-c3502172659-8"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              className="flip-card ng-tns-c3502172659-8"
                            >
                              <div
                                _ngcontent-ng-c3502172659
                                className="flip-card-inner ng-tns-c3502172659-8 ng-trigger ng-trigger-flip"
                              >
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="flip-card-front ng-tns-c3502172659-8"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="up ng-tns-c3502172659-8"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="ng-tns-c3502172659-8"
                                    >
                                      1
                                    </div>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="flip-card-back ng-tns-c3502172659-8"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="down ng-tns-c3502172659-8"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="ng-tns-c3502172659-8"
                                    >
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="ng-tns-c3502172659-8"
                                      >
                                        1
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </span>
                    </div>
                    {/**/}
                  </div>
                </div>
                {/**/}
                {/**/}
                <div
                  _ngcontent-ng-c3502172659
                  className="casino-detail ng-tns-c3502172659-8"
                >
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  <div
                    _ngcontent-ng-c3502172659
                    className="casino-detail ng-tns-c3502172659-8 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c3502172659
                      className="ball-by-ball ng-tns-c3502172659-8"
                    >
                      <div
                        _ngcontent-ng-c3502172659
                        className="game-market market-6 ng-tns-c3502172659-8"
                      >
                        <div
                          _ngcontent-ng-c3502172659
                          className="market-title ng-tns-c3502172659-8"
                        >
                          Runs
                        </div>
                        <div
                          _ngcontent-ng-c3502172659
                          className="market-header row ng-tns-c3502172659-8"
                        >
                          <div
                            _ngcontent-ng-c3502172659
                            className="col-6 col-md-4 ng-tns-c3502172659-8"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              className="market-row ng-tns-c3502172659-8"
                            >
                              <div
                                _ngcontent-ng-c3502172659
                                className="market-nation-detail ng-tns-c3502172659-8"
                              />
                              <div
                                _ngcontent-ng-c3502172659
                                className="market-odd-box back ng-tns-c3502172659-8"
                              >
                                <b
                                  _ngcontent-ng-c3502172659
                                  className="ng-tns-c3502172659-8"
                                >
                                  Back
                                </b>
                              </div>
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="col-6 col-md-4 ng-tns-c3502172659-8"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              className="market-row ng-tns-c3502172659-8"
                            >
                              <div
                                _ngcontent-ng-c3502172659
                                className="market-nation-detail ng-tns-c3502172659-8"
                              />
                              <div
                                _ngcontent-ng-c3502172659
                                className="market-odd-box back ng-tns-c3502172659-8"
                              >
                                <b
                                  _ngcontent-ng-c3502172659
                                  className="ng-tns-c3502172659-8"
                                >
                                  Back
                                </b>
                              </div>
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="col-6 col-md-4 d-none d-md-block ng-tns-c3502172659-8"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              className="market-row ng-tns-c3502172659-8"
                            >
                              <div
                                _ngcontent-ng-c3502172659
                                className="market-nation-detail ng-tns-c3502172659-8"
                              />
                              <div
                                _ngcontent-ng-c3502172659
                                className="market-odd-box back ng-tns-c3502172659-8"
                              >
                                <b
                                  _ngcontent-ng-c3502172659
                                  className="ng-tns-c3502172659-8"
                                >
                                  Back
                                </b>
                              </div>
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="fancy-min-max-box ng-tns-c3502172659-8"
                          />
                        </div>
                        <div
                          _ngcontent-ng-c3502172659
                          className="market-body row ng-tns-c3502172659-8"
                        >
                          <div
                            _ngcontent-ng-c3502172659
                            className="col-6 col-md-4 ng-tns-c3502172659-8 ng-star-inserted"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              data-title="SUSPENDED"
                              className="fancy-market ng-tns-c3502172659-8"
                            >
                              <div
                                _ngcontent-ng-c3502172659
                                className="market-row ng-tns-c3502172659-8"
                              >
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="market-nation-detail ng-tns-c3502172659-8"
                                >
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="market-nation-name pointer ng-tns-c3502172659-8"
                                  >
                                    0 Runs
                                  </span>
                                  <span
                                    _ngcontent-ng-c3502172659
                                    id="book_1"
                                    className="float-left book ng-tns-c3502172659-8"
                                    style={{ color: "black" }}
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="ng-tns-c3502172659-8"
                                    >
                                      {" "}
                                      0{" "}
                                    </span>
                                  </span>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="blb-box ng-tns-c3502172659-8"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="market-odd-box back ng-tns-c3502172659-8"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="market-odd ng-tns-c3502172659-8"
                                    >
                                      {" "}
                                      2.18
                                    </span>
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="market-volume ng-tns-c3502172659-8"
                                    >
                                      {" "}
                                      0{" "}
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="fancy-min-max ng-tns-c3502172659-8"
                                >
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="w-100 d-block ng-tns-c3502172659-8"
                                  >
                                    Min: 50
                                  </span>
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="w-100 d-block ng-tns-c3502172659-8"
                                  >
                                    Max: 25000
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/**/}
                          <div
                            _ngcontent-ng-c3502172659
                            className="col-6 col-md-4 ng-tns-c3502172659-8 ng-star-inserted"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              data-title="SUSPENDED"
                              className="fancy-market ng-tns-c3502172659-8"
                            >
                              <div
                                _ngcontent-ng-c3502172659
                                className="market-row ng-tns-c3502172659-8"
                              >
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="market-nation-detail ng-tns-c3502172659-8"
                                >
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="market-nation-name pointer ng-tns-c3502172659-8"
                                  >
                                    1 Runs
                                  </span>
                                  <span
                                    _ngcontent-ng-c3502172659
                                    id="book_1"
                                    className="float-left book ng-tns-c3502172659-8"
                                    style={{ color: "black" }}
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="ng-tns-c3502172659-8"
                                    >
                                      {" "}
                                      0{" "}
                                    </span>
                                  </span>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="blb-box ng-tns-c3502172659-8"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="market-odd-box back ng-tns-c3502172659-8"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="market-odd ng-tns-c3502172659-8"
                                    >
                                      {" "}
                                      2.63
                                    </span>
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="market-volume ng-tns-c3502172659-8"
                                    >
                                      {" "}
                                      0{" "}
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="fancy-min-max ng-tns-c3502172659-8"
                                >
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="w-100 d-block ng-tns-c3502172659-8"
                                  >
                                    Min: 50
                                  </span>
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="w-100 d-block ng-tns-c3502172659-8"
                                  >
                                    Max: 25000
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/**/}
                          <div
                            _ngcontent-ng-c3502172659
                            className="col-6 col-md-4 ng-tns-c3502172659-8 ng-star-inserted"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              data-title="SUSPENDED"
                              className="fancy-market ng-tns-c3502172659-8"
                            >
                              <div
                                _ngcontent-ng-c3502172659
                                className="market-row ng-tns-c3502172659-8"
                              >
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="market-nation-detail ng-tns-c3502172659-8"
                                >
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="market-nation-name pointer ng-tns-c3502172659-8"
                                  >
                                    2 Runs
                                  </span>
                                  <span
                                    _ngcontent-ng-c3502172659
                                    id="book_1"
                                    className="float-left book ng-tns-c3502172659-8"
                                    style={{ color: "black" }}
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="ng-tns-c3502172659-8"
                                    >
                                      {" "}
                                      0{" "}
                                    </span>
                                  </span>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="blb-box ng-tns-c3502172659-8"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="market-odd-box back ng-tns-c3502172659-8"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="market-odd ng-tns-c3502172659-8"
                                    >
                                      {" "}
                                      7.94
                                    </span>
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="market-volume ng-tns-c3502172659-8"
                                    >
                                      {" "}
                                      0{" "}
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="fancy-min-max ng-tns-c3502172659-8"
                                >
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="w-100 d-block ng-tns-c3502172659-8"
                                  >
                                    Min: 50
                                  </span>
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="w-100 d-block ng-tns-c3502172659-8"
                                  >
                                    Max: 25000
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/**/}
                          <div
                            _ngcontent-ng-c3502172659
                            className="col-6 col-md-4 ng-tns-c3502172659-8 ng-star-inserted"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              data-title="SUSPENDED"
                              className="fancy-market ng-tns-c3502172659-8"
                            >
                              <div
                                _ngcontent-ng-c3502172659
                                className="market-row ng-tns-c3502172659-8"
                              >
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="market-nation-detail ng-tns-c3502172659-8"
                                >
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="market-nation-name pointer ng-tns-c3502172659-8"
                                  >
                                    3 Runs
                                  </span>
                                  <span
                                    _ngcontent-ng-c3502172659
                                    id="book_1"
                                    className="float-left book ng-tns-c3502172659-8"
                                    style={{ color: "black" }}
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="ng-tns-c3502172659-8"
                                    >
                                      {" "}
                                      0{" "}
                                    </span>
                                  </span>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="blb-box ng-tns-c3502172659-8"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="market-odd-box back ng-tns-c3502172659-8"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="market-odd ng-tns-c3502172659-8"
                                    >
                                      {" "}
                                      11
                                    </span>
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="market-volume ng-tns-c3502172659-8"
                                    >
                                      {" "}
                                      0{" "}
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="fancy-min-max ng-tns-c3502172659-8"
                                >
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="w-100 d-block ng-tns-c3502172659-8"
                                  >
                                    Min: 50
                                  </span>
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="w-100 d-block ng-tns-c3502172659-8"
                                  >
                                    Max: 25000
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/**/}
                          <div
                            _ngcontent-ng-c3502172659
                            className="col-6 col-md-4 ng-tns-c3502172659-8 ng-star-inserted"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              data-title="SUSPENDED"
                              className="fancy-market ng-tns-c3502172659-8"
                            >
                              <div
                                _ngcontent-ng-c3502172659
                                className="market-row ng-tns-c3502172659-8"
                              >
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="market-nation-detail ng-tns-c3502172659-8"
                                >
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="market-nation-name pointer ng-tns-c3502172659-8"
                                  >
                                    4 Runs
                                  </span>
                                  <span
                                    _ngcontent-ng-c3502172659
                                    id="book_1"
                                    className="float-left book ng-tns-c3502172659-8"
                                    style={{ color: "black" }}
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="ng-tns-c3502172659-8"
                                    >
                                      {" "}
                                      0{" "}
                                    </span>
                                  </span>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="blb-box ng-tns-c3502172659-8"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="market-odd-box back ng-tns-c3502172659-8"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="market-odd ng-tns-c3502172659-8"
                                    >
                                      {" "}
                                      6.57
                                    </span>
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="market-volume ng-tns-c3502172659-8"
                                    >
                                      {" "}
                                      0{" "}
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="fancy-min-max ng-tns-c3502172659-8"
                                >
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="w-100 d-block ng-tns-c3502172659-8"
                                  >
                                    Min: 50
                                  </span>
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="w-100 d-block ng-tns-c3502172659-8"
                                  >
                                    Max: 25000
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/**/}
                          <div
                            _ngcontent-ng-c3502172659
                            className="col-6 col-md-4 ng-tns-c3502172659-8 ng-star-inserted"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              data-title="SUSPENDED"
                              className="fancy-market ng-tns-c3502172659-8"
                            >
                              <div
                                _ngcontent-ng-c3502172659
                                className="market-row ng-tns-c3502172659-8"
                              >
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="market-nation-detail ng-tns-c3502172659-8"
                                >
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="market-nation-name pointer ng-tns-c3502172659-8"
                                  >
                                    6 Runs
                                  </span>
                                  <span
                                    _ngcontent-ng-c3502172659
                                    id="book_1"
                                    className="float-left book ng-tns-c3502172659-8"
                                    style={{ color: "black" }}
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="ng-tns-c3502172659-8"
                                    >
                                      {" "}
                                      0{" "}
                                    </span>
                                  </span>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="blb-box ng-tns-c3502172659-8"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="market-odd-box back ng-tns-c3502172659-8"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="market-odd ng-tns-c3502172659-8"
                                    >
                                      {" "}
                                      11.9
                                    </span>
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="market-volume ng-tns-c3502172659-8"
                                    >
                                      {" "}
                                      0{" "}
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="fancy-min-max ng-tns-c3502172659-8"
                                >
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="w-100 d-block ng-tns-c3502172659-8"
                                  >
                                    Min: 50
                                  </span>
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="w-100 d-block ng-tns-c3502172659-8"
                                  >
                                    Max: 20000
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/**/}
                          <div
                            _ngcontent-ng-c3502172659
                            className="col-6 col-md-4 ng-tns-c3502172659-8 ng-star-inserted"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              data-title="SUSPENDED"
                              className="fancy-market ng-tns-c3502172659-8"
                            >
                              <div
                                _ngcontent-ng-c3502172659
                                className="market-row ng-tns-c3502172659-8"
                              >
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="market-nation-detail ng-tns-c3502172659-8"
                                >
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="market-nation-name pointer ng-tns-c3502172659-8"
                                  >
                                    Boundary
                                  </span>
                                  <span
                                    _ngcontent-ng-c3502172659
                                    id="book_1"
                                    className="float-left book ng-tns-c3502172659-8"
                                    style={{ color: "black" }}
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="ng-tns-c3502172659-8"
                                    >
                                      {" "}
                                      0{" "}
                                    </span>
                                  </span>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="blb-box ng-tns-c3502172659-8"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="market-odd-box back ng-tns-c3502172659-8"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="market-odd ng-tns-c3502172659-8"
                                    >
                                      {" "}
                                      4.23
                                    </span>
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="market-volume ng-tns-c3502172659-8"
                                    >
                                      {" "}
                                      0{" "}
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="fancy-min-max ng-tns-c3502172659-8"
                                >
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="w-100 d-block ng-tns-c3502172659-8"
                                  >
                                    Min: 50
                                  </span>
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="w-100 d-block ng-tns-c3502172659-8"
                                  >
                                    Max: 25000
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/**/}
                          <div
                            _ngcontent-ng-c3502172659
                            className="col-6 col-md-4 ng-tns-c3502172659-8 ng-star-inserted"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              data-title="SUSPENDED"
                              className="fancy-market ng-tns-c3502172659-8"
                            >
                              <div
                                _ngcontent-ng-c3502172659
                                className="market-row ng-tns-c3502172659-8"
                              >
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="market-nation-detail ng-tns-c3502172659-8"
                                >
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="market-nation-name pointer ng-tns-c3502172659-8"
                                  >
                                    Wicket
                                  </span>
                                  <span
                                    _ngcontent-ng-c3502172659
                                    id="book_1"
                                    className="float-left book ng-tns-c3502172659-8"
                                    style={{ color: "black" }}
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="ng-tns-c3502172659-8"
                                    >
                                      {" "}
                                      0{" "}
                                    </span>
                                  </span>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="blb-box ng-tns-c3502172659-8"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="market-odd-box back ng-tns-c3502172659-8"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="market-odd ng-tns-c3502172659-8"
                                    >
                                      {" "}
                                      6.87
                                    </span>
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="market-volume ng-tns-c3502172659-8"
                                    >
                                      {" "}
                                      0{" "}
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="fancy-min-max ng-tns-c3502172659-8"
                                >
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="w-100 d-block ng-tns-c3502172659-8"
                                  >
                                    Min: 50
                                  </span>
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="w-100 d-block ng-tns-c3502172659-8"
                                  >
                                    Max: 25000
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/**/}
                          <div
                            _ngcontent-ng-c3502172659
                            className="col-6 col-md-4 ng-tns-c3502172659-8 ng-star-inserted"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              data-title="SUSPENDED"
                              className="fancy-market ng-tns-c3502172659-8"
                            >
                              <div
                                _ngcontent-ng-c3502172659
                                className="market-row ng-tns-c3502172659-8"
                              >
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="market-nation-detail ng-tns-c3502172659-8"
                                >
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="market-nation-name pointer ng-tns-c3502172659-8"
                                  >
                                    Extra Runs
                                  </span>
                                  <span
                                    _ngcontent-ng-c3502172659
                                    id="book_1"
                                    className="float-left book ng-tns-c3502172659-8"
                                    style={{ color: "black" }}
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="ng-tns-c3502172659-8"
                                    >
                                      {" "}
                                      0{" "}
                                    </span>
                                  </span>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="blb-box ng-tns-c3502172659-8"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="market-odd-box back ng-tns-c3502172659-8"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="market-odd ng-tns-c3502172659-8"
                                    >
                                      {" "}
                                      8.54
                                    </span>
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="market-volume ng-tns-c3502172659-8"
                                    >
                                      {" "}
                                      0{" "}
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="fancy-min-max ng-tns-c3502172659-8"
                                >
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="w-100 d-block ng-tns-c3502172659-8"
                                  >
                                    Min: 50
                                  </span>
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="w-100 d-block ng-tns-c3502172659-8"
                                  >
                                    Max: 25000
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/**/}
                          {/**/}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/**/}
                  <div
                    _ngcontent-ng-c3502172659
                    className="ng-tns-c3502172659-8"
                  >
                    <div
                      _ngcontent-ng-c3502172659
                      className="casino-last-result-title ng-tns-c3502172659-8"
                    >
                      <span
                        _ngcontent-ng-c3502172659
                        className="ng-tns-c3502172659-8"
                      >
                        Last Result
                      </span>
                      <span
                        _ngcontent-ng-c3502172659
                        className="ng-tns-c3502172659-8"
                      >
                        <a
                          _ngcontent-ng-c3502172659
                          className="ng-tns-c3502172659-8"
                        >
                          View All
                        </a>
                      </span>
                    </div>
                    <div
                      _ngcontent-ng-c3502172659
                      className="casino-last-results ng-tns-c3502172659-8 ng-star-inserted"
                    >
                      <span
                        _ngcontent-ng-c3502172659
                        className="ng-tns-c3502172659-8 result ng-star-inserted"
                      >
                        <span
                          _ngcontent-ng-c3502172659
                          className="ng-tns-c3502172659-8"
                        >
                          {" "}
                          R
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c3502172659
                        className="ng-tns-c3502172659-8 result ng-star-inserted"
                      >
                        <span
                          _ngcontent-ng-c3502172659
                          className="ng-tns-c3502172659-8"
                        >
                          {" "}
                          R
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c3502172659
                        className="ng-tns-c3502172659-8 result ng-star-inserted"
                      >
                        <span
                          _ngcontent-ng-c3502172659
                          className="ng-tns-c3502172659-8"
                        >
                          {" "}
                          R
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c3502172659
                        className="ng-tns-c3502172659-8 result ng-star-inserted"
                      >
                        <span
                          _ngcontent-ng-c3502172659
                          className="ng-tns-c3502172659-8"
                        >
                          {" "}
                          R
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c3502172659
                        className="ng-tns-c3502172659-8 result ng-star-inserted"
                      >
                        <span
                          _ngcontent-ng-c3502172659
                          className="ng-tns-c3502172659-8"
                        >
                          {" "}
                          R
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c3502172659
                        className="ng-tns-c3502172659-8 result ng-star-inserted"
                      >
                        <span
                          _ngcontent-ng-c3502172659
                          className="ng-tns-c3502172659-8"
                        >
                          {" "}
                          R
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c3502172659
                        className="ng-tns-c3502172659-8 result ng-star-inserted"
                      >
                        <span
                          _ngcontent-ng-c3502172659
                          className="ng-tns-c3502172659-8"
                        >
                          {" "}
                          R
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c3502172659
                        className="ng-tns-c3502172659-8 result ng-star-inserted"
                      >
                        <span
                          _ngcontent-ng-c3502172659
                          className="ng-tns-c3502172659-8"
                        >
                          {" "}
                          R
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c3502172659
                        className="ng-tns-c3502172659-8 result ng-star-inserted"
                      >
                        <span
                          _ngcontent-ng-c3502172659
                          className="ng-tns-c3502172659-8"
                        >
                          {" "}
                          R
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c3502172659
                        className="ng-tns-c3502172659-8 result ng-star-inserted"
                      >
                        <span
                          _ngcontent-ng-c3502172659
                          className="ng-tns-c3502172659-8"
                        >
                          {" "}
                          R
                        </span>
                      </span>
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                    </div>
                    {/**/}
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c3502172659
                className="ng-tns-c3502172659-8 d-none matchbettable w-100 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c3502172659
                  className="table-responsive ng-tns-c3502172659-8"
                >
                  <table
                    _ngcontent-ng-c3502172659
                    className="table ng-tns-c3502172659-8"
                  >
                    <thead
                      _ngcontent-ng-c3502172659
                      className="ng-tns-c3502172659-8"
                    >
                      <tr
                        _ngcontent-ng-c3502172659
                        className="ng-tns-c3502172659-8"
                      >
                        <th
                          _ngcontent-ng-c3502172659
                          className="ng-tns-c3502172659-8"
                        >
                          Matched Bet
                        </th>
                        <th
                          _ngcontent-ng-c3502172659
                          className="text-end ng-tns-c3502172659-8"
                        >
                          Odds
                        </th>
                        <th
                          _ngcontent-ng-c3502172659
                          className="text-end ng-tns-c3502172659-8"
                        >
                          Stake
                        </th>
                      </tr>
                    </thead>
                    {/**/}
                  </table>
                </div>
              </div>
              {/**/}
              {/**/}
            </div>
            {/**/}
          </div>
          <div
            _ngcontent-ng-c3502172659
            className="sidebar right-sidebar casino-right-sidebar ng-tns-c3502172659-8"
          >
            <div
              _ngcontent-ng-c3502172659
              className="sidebar-box place-bet-container d-xl-block d-none ng-tns-c3502172659-8"
            >
              {/**/}
              <div
                _ngcontent-ng-c3502172659
                className="sidebar-title ng-tns-c3502172659-8"
              >
                <h4 _ngcontent-ng-c3502172659 className="ng-tns-c3502172659-8">
                  Place Bet
                </h4>
              </div>
              {/**/}
            </div>
            <div
              _ngcontent-ng-c3502172659
              className="sidebar-box my-bet-container d-xl-block d-none ng-tns-c3502172659-8"
            >
              <div
                _ngcontent-ng-c3502172659
                className="sidebar-title ng-tns-c3502172659-8"
              >
                <h4 _ngcontent-ng-c3502172659 className="ng-tns-c3502172659-8">
                  My Bet
                </h4>
                <a
                  _ngcontent-ng-c3502172659
                  className="sideviewmore ng-tns-c3502172659-8"
                >
                  View more
                </a>
              </div>
              <div
                _ngcontent-ng-c3502172659
                className="my-bets ng-tns-c3502172659-8"
              >
                <div
                  _ngcontent-ng-c3502172659
                  className="table-responsive w-100 ng-tns-c3502172659-8"
                >
                  <table
                    _ngcontent-ng-c3502172659
                    className="table ng-tns-c3502172659-8"
                  >
                    <thead
                      _ngcontent-ng-c3502172659
                      className="ng-tns-c3502172659-8"
                    >
                      <tr
                        _ngcontent-ng-c3502172659
                        className="ng-tns-c3502172659-8"
                      >
                        <th
                          _ngcontent-ng-c3502172659
                          className="ng-tns-c3502172659-8"
                        >
                          Matched Bet
                        </th>
                        <th
                          _ngcontent-ng-c3502172659
                          className="text-end ng-tns-c3502172659-8"
                        >
                          Odds
                        </th>
                        <th
                          _ngcontent-ng-c3502172659
                          className="text-end ng-tns-c3502172659-8"
                        >
                          Stake
                        </th>
                      </tr>
                    </thead>
                    {/**/}
                  </table>
                </div>
              </div>
            </div>
            <div
              _ngcontent-ng-c3502172659
              className="sidebar-box sidebar-box-rules my-bet-container ng-tns-c3502172659-8"
            >
              <app-casino-game-rules
                _ngcontent-ng-c3502172659
                className="ng-tns-c3502172659-8"
                _nghost-ng-c1761710207
              >
                {/**/}
                {/**/}
                {/**/}
                {/**/}
              </app-casino-game-rules>
            </div>
          </div>
        </div>
        {/**/}
      </div>
      <div
        _ngcontent-ng-c3502172659
        id="placebetmodal"
        tabIndex={-1}
        aria-labelledby="placebetmodalLabel"
        aria-hidden="true"
        className="modal fade ng-tns-c3502172659-8"
      >
        <div
          _ngcontent-ng-c3502172659
          className="modal-dialog ng-tns-c3502172659-8"
        >
          <div
            _ngcontent-ng-c3502172659
            className="modal-content ng-tns-c3502172659-8"
          >
            <div
              _ngcontent-ng-c3502172659
              className="modal-header ng-tns-c3502172659-8"
            >
              <h5
                _ngcontent-ng-c3502172659
                id="placebetmodalLabel"
                className="modal-title ng-tns-c3502172659-8"
              >
                Place Bet
              </h5>
              <button
                _ngcontent-ng-c3502172659
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
                className="btn-close ng-tns-c3502172659-8"
              />
            </div>
            <div
              _ngcontent-ng-c3502172659
              className="modal-body ng-tns-c3502172659-8"
            >
              {/**/}
              <div
                _ngcontent-ng-c3502172659
                className="place-bet-modal ng-tns-c3502172659-8 lay"
              >
                <div
                  _ngcontent-ng-c3502172659
                  className="row ng-tns-c3502172659-8"
                >
                  <div
                    _ngcontent-ng-c3502172659
                    className="col-6 ng-tns-c3502172659-8"
                  >
                    <b
                      _ngcontent-ng-c3502172659
                      className="ng-tns-c3502172659-8"
                    />
                  </div>
                  <div
                    _ngcontent-ng-c3502172659
                    className="col-6 ng-tns-c3502172659-8"
                  >
                    <div
                      _ngcontent-ng-c3502172659
                      className="float-end ng-tns-c3502172659-8"
                    >
                      <button
                        _ngcontent-ng-c3502172659
                        className="stakeactionminus btn ng-tns-c3502172659-8"
                      >
                        <span
                          _ngcontent-ng-c3502172659
                          className="fa fa-minus ng-tns-c3502172659-8"
                        />
                      </button>
                      <input
                        _ngcontent-ng-c3502172659
                        type="text"
                        className="stakeinput ng-tns-c3502172659-8 ng-untouched ng-pristine"
                        disabled
                      />
                      <button
                        _ngcontent-ng-c3502172659
                        className="stakeactionminus btn ng-tns-c3502172659-8"
                      >
                        <span
                          _ngcontent-ng-c3502172659
                          className="fa fa-plus ng-tns-c3502172659-8"
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c3502172659
                  className="row mt-2 ng-tns-c3502172659-8"
                >
                  <div
                    _ngcontent-ng-c3502172659
                    className="col-4 ng-tns-c3502172659-8"
                  >
                    <input
                      _ngcontent-ng-c3502172659
                      type="number"
                      className="stakeinput w-100 ng-tns-c3502172659-8 ng-untouched ng-pristine ng-valid"
                    />
                  </div>
                  <div
                    _ngcontent-ng-c3502172659
                    className="col-4 d-grid ps-0 ng-tns-c3502172659-8"
                  >
                    <button
                      _ngcontent-ng-c3502172659
                      className="btn btn-primary btn-block btn-loader ng-tns-c3502172659-8"
                    >
                      {/**/} Submit{" "}
                    </button>
                  </div>
                  <div
                    _ngcontent-ng-c3502172659
                    className="col-4 text-center pt-2 ng-tns-c3502172659-8"
                  >
                    {/**/}
                    {/**/}
                  </div>
                </div>
                <div
                  _ngcontent-ng-c3502172659
                  className="place-bet-buttons mt-2 ng-tns-c3502172659-8"
                >
                  <button
                    _ngcontent-ng-c3502172659
                    className="btn btn-place-bet ng-tns-c3502172659-8 ng-star-inserted"
                  >
                    {" "}
                    100{" "}
                  </button>
                  {/**/}
                  <button
                    _ngcontent-ng-c3502172659
                    className="btn btn-place-bet ng-tns-c3502172659-8 ng-star-inserted"
                  >
                    {" "}
                    200{" "}
                  </button>
                  {/**/}
                  <button
                    _ngcontent-ng-c3502172659
                    className="btn btn-place-bet ng-tns-c3502172659-8 ng-star-inserted"
                  >
                    {" "}
                    500{" "}
                  </button>
                  {/**/}
                  <button
                    _ngcontent-ng-c3502172659
                    className="btn btn-place-bet ng-tns-c3502172659-8 ng-star-inserted"
                  >
                    {" "}
                    1000{" "}
                  </button>
                  {/**/}
                  <button
                    _ngcontent-ng-c3502172659
                    className="btn btn-place-bet ng-tns-c3502172659-8 ng-star-inserted"
                  >
                    {" "}
                    10000{" "}
                  </button>
                  {/**/}
                  <button
                    _ngcontent-ng-c3502172659
                    className="btn btn-place-bet ng-tns-c3502172659-8 ng-star-inserted"
                  >
                    {" "}
                    20000{" "}
                  </button>
                  {/**/}
                  <button
                    _ngcontent-ng-c3502172659
                    className="btn btn-place-bet ng-tns-c3502172659-8 ng-star-inserted"
                  >
                    {" "}
                    50000{" "}
                  </button>
                  {/**/}
                  <button
                    _ngcontent-ng-c3502172659
                    className="btn btn-place-bet ng-tns-c3502172659-8 ng-star-inserted"
                  >
                    {" "}
                    100000{" "}
                  </button>
                  {/**/}
                  <button
                    _ngcontent-ng-c3502172659
                    className="btn btn-place-bet ng-tns-c3502172659-8 ng-star-inserted"
                  >
                    {" "}
                    200000{" "}
                  </button>
                  {/**/}
                  <button
                    _ngcontent-ng-c3502172659
                    className="btn btn-place-bet ng-tns-c3502172659-8 ng-star-inserted"
                  >
                    {" "}
                    500000{" "}
                  </button>
                  {/**/}
                  {/**/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        _ngcontent-ng-c3502172659
        id="rulesmodal"
        tabIndex={-1}
        aria-labelledby="teenpattidLabel"
        aria-hidden="true"
        className="modal fade rules-modal ng-tns-c3502172659-8"
      >
        <div
          _ngcontent-ng-c3502172659
          className="modal-dialog ng-tns-c3502172659-8"
        >
          <div
            _ngcontent-ng-c3502172659
            className="modal-content ng-tns-c3502172659-8"
          >
            <div
              _ngcontent-ng-c3502172659
              className="modal-header ng-tns-c3502172659-8"
            >
              <h5
                _ngcontent-ng-c3502172659
                id="teenpattidLabel"
                className="modal-title ng-tns-c3502172659-8"
              >
                Rules
              </h5>
              <button
                _ngcontent-ng-c3502172659
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
                className="btn-close ng-tns-c3502172659-8"
              />
            </div>
            <app-casino-rules-modal
              _ngcontent-ng-c3502172659
              className="ng-tns-c3502172659-8"
              _nghost-ng-c313361437
            >
              <div _ngcontent-ng-c313361437 className="modal-body p-0">
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
              </div>
            </app-casino-rules-modal>
          </div>
        </div>
      </div>
      <div
        _ngcontent-ng-c3502172659
        id="Books"
        tabIndex={-1}
        aria-labelledby="MatchOddsModalLabel"
        aria-hidden="true"
        className="modal fade ng-tns-c3502172659-8"
      >
        <div
          _ngcontent-ng-c3502172659
          className="modal-dialog match-details-rules ng-tns-c3502172659-8"
        >
          <div
            _ngcontent-ng-c3502172659
            className="modal-content ng-tns-c3502172659-8"
          >
            <div
              _ngcontent-ng-c3502172659
              className="modal-header ng-tns-c3502172659-8"
            >
              <h5
                _ngcontent-ng-c3502172659
                id="ResultModalLabel"
                className="modal-title ng-tns-c3502172659-8"
              >
                Books
              </h5>
              <button
                _ngcontent-ng-c3502172659
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
                className="btn-close ng-tns-c3502172659-8"
              />
            </div>
            <div
              _ngcontent-ng-c3502172659
              className="modal-body ng-tns-c3502172659-8"
            >
              <div
                _ngcontent-ng-c3502172659
                className="table-responsive ng-tns-c3502172659-8"
              >
                <table
                  _ngcontent-ng-c3502172659
                  className="table table-bordered ng-tns-c3502172659-8"
                >
                  <thead
                    _ngcontent-ng-c3502172659
                    className="ng-tns-c3502172659-8"
                  >
                    <tr
                      _ngcontent-ng-c3502172659
                      className="ng-tns-c3502172659-8"
                    >
                      <th
                        _ngcontent-ng-c3502172659
                        className="ng-tns-c3502172659-8"
                      >
                        Run
                      </th>
                      <th
                        _ngcontent-ng-c3502172659
                        className="ng-tns-c3502172659-8"
                      >
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    _ngcontent-ng-c3502172659
                    className="ng-tns-c3502172659-8"
                  >
                    {/**/}
                    <tr
                      _ngcontent-ng-c3502172659
                      className="ng-tns-c3502172659-8 ng-star-inserted"
                    >
                      <td
                        _ngcontent-ng-c3502172659
                        colSpan={2}
                        className="text-center ng-tns-c3502172659-8"
                      >
                        No Record found !
                      </td>
                    </tr>
                    {/**/}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        _ngcontent-ng-c3502172659
        id="BookMarkerModal"
        tabIndex={-1}
        aria-labelledby="BookMarkerModalLabel"
        aria-hidden="true"
        className="modal fade ng-tns-c3502172659-8"
      >
        <div
          _ngcontent-ng-c3502172659
          className="modal-dialog match-details-rules ng-tns-c3502172659-8"
        >
          <div
            _ngcontent-ng-c3502172659
            className="modal-content ng-tns-c3502172659-8"
          >
            <app-bookmarker
              _ngcontent-ng-c3502172659
              className="ng-tns-c3502172659-8"
              _nghost-ng-c3168318478
            >
              <div _ngcontent-ng-c3168318478 className="modal-header">
                <h5
                  _ngcontent-ng-c3168318478
                  id="ResultModalLabel"
                  className="modal-title"
                >
                  Rules
                </h5>
                <button
                  _ngcontent-ng-c3168318478
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="btn-close"
                />
              </div>
              <div _ngcontent-ng-c3168318478 className="modal-body p-3">
                <div _ngcontent-ng-c3168318478 className="card-header mb-3">
                  <div _ngcontent-ng-c3168318478 className="card-title">
                    {" "}
                    BookMarker{" "}
                  </div>
                </div>
                <div _ngcontent-ng-c3168318478 className="rules-table-design">
                  <table
                    _ngcontent-ng-c3168318478
                    className="table table-bordered w-100"
                  >
                    <tbody _ngcontent-ng-c3168318478>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            1. Even odd game betting rate rules.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            1.1 Completed game is valid , in case due to rain
                            over are reduced or match abandoned particular game
                            will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            1.2 All bets regarding to ODD/EVEN
                            player/partnership are valid if one legal delivery
                            is being played, else the bets will be deleted.
                            Player odd/even all advance bets will be valid if
                            one legal delivery is being played in match
                            otherwise voided.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            1.3 All bets regarding Odd/Even sessions will be
                            deleted if the particular session is incomplete, for
                            example match got abandoned or finished before the
                            end of particular session.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            1.4 In any circumstances management decision will be
                            final.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            2 Top batsman rules:-
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            2.1 If any player does not come as per playing
                            eleven then all bets will be get deleted for the
                            particular player.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            2.2 two players done the same run in a single match
                            (M Agarwal 30 runs and A Rayudu 30 runs, whole
                            inning top batsmen score also 30 run) then both
                            player settlement to be get done 50 percent (50% ,
                            50%)rate on their original value which given by our
                            exchange.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Suppose we have opened value of M Agarwal 3.75 back
                            and customer place bets on 10000 @ 3.75 rates and A
                            Rayudu 3.0 back and customer place bets on 10000 @
                            3.0 rates.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Whole inning result announces 30 run by both player
                            then
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Rule of top batsman:-if you bet on M Agarwal you
                            will be get half amount of this rate
                            (10000*3.75/2=18750 you will get)
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Rule of top batsman:-if you bet on A Rayudu you will
                            be get half amount of this rate (10000*3.00/2=15000
                            you will get)
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Top batsman only 1st inning valid.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            For one day 50 over and for t-20 match 20 over must
                            be played for top batsmen otherwise all bets will be
                            deleted.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Man of the Match Rules
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            1. All bets will be deleted in case the match is
                            abandoned or over reduced.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            2. All bets will be deleted if the mentioned player
                            is not included in playing 11.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            3. In case Man of the Match is shared between two
                            players then Dead heat rule will be applicable, For
                            example K Perera and T Iqbal shares the Man of the
                            Match, then the settlement will be done 50% of the
                            rates accordingly.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            4. Rules similar to our Top Batsman rules.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Maximum Sixes by Team
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            1. All bets will be deleted if match abandoned or
                            over reduced
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            2. All bets will be deleted if both the teams hits
                            same number of sixes.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            3. Super over will not be considered.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Maximum 6 or 10 over runs
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            1. All bets will be deleted if match abandoned or
                            over reduced.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            2. All the bets will be deleted if both the teams
                            score is same (Runs scored in 6 or 10 overs)
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            3. 6 overs for T20 and 10 overs for ODI
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            4. Both the innings are valid.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            5. This fancy will be valid for 1st 6 overs of both
                            innings for T20 and 1st 10 overs of both innings for
                            ODI
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Batsman Match
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Batsman Match:- Bets for Favourite batsman from the
                            two batsman matched.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            All bets will be deleted if any one of the mentioned
                            player is not included in playing 11.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            All bets will be deleted unless one ball being
                            played by both the mentioned players.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            All bets will be deleted if over reduced or Match
                            abandoned.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            All bets will be deleted if both the player scored
                            same run. For example H Amla and J Bairstow are the
                            batsman matched, H Amla and J Bairstow both scored
                            38 runs then all bets will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Both innings will be valid
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Opening Pair
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            1. Bets for Favourite opening pair from the two
                            mentioned opening pair.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            2. Runs made by both the opening player will be
                            added. For example:- J Roy scored 20 runs and J
                            Bairstow scored 30 runs result will be 50 runs.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            3. Highest run made by the pair will be declared as
                            winner. For example: Opening pair ENG total is 70
                            runs and Opening pair SA is 90 runs, then SA 90 runs
                            will be declared as winner.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Both innings will be valid
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Our exchange Special
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            All bets will be deleted if the mentioned player is
                            not included in playing 11.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            All bets will be deleted if match abandoned or over
                            reduced.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Both innings will be valid
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Direction of First Boundary
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            All bets will be deleted if the mentioned batsman is
                            not included in playing 11.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            All bets will be deleted if match abandoned or over
                            reduced.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            The boundary hit through off side of the stump will
                            be considered as off side four.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            The boundary hit through leg side of the stump will
                            be considered as leg side four.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Boundaries through extras (byes,leg
                            byes,wide,overthrow) will not be considered as
                            valid.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Only 1st Inning will be considered
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Fifty &amp; Century by Batsman
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            All bets will be deleted if match abandoned or over
                            reduced.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            All bets will be deleted if the mentioned batsman is
                            not included in playing 11.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            All bets will be deleted unless the batsman faces
                            one legal ball.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Both Innings will be valid.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            1st over Fancy
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Boundaries through extras (byes,leg
                            byes,wide,overthrow) will not be considered.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Only 1st inning will be valid
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Odd Even Fancy
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Incompleted games will be deleted. Over reduced or
                            abandoned all bets will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            For example:-275 run SL bhav must be played 50 over
                            if rain comes or any condition otherwise 275 run SL
                            bets will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Next Man out
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Next man out fancy advance &amp; in regular. Both
                            inning will be valid. If any player does not come in
                            opening then all bets will be deleted. If over
                            reduced or abandoned then all bets will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Caught out
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Caught out fancy in advance &amp; in regular. Both
                            inning will be valid. If over reduced or match
                            abandoned then all bets will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Wkt &amp; All out Fancy
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            5 wkt in 10 over &amp; All out in 20 over fancy is
                            valid for both inning. If match abandoned or over
                            reduced all bets will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Test Match: Game Completed Fancy
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            1. This is the fancy for match to be won/ completed
                            in which day &amp; session (Completed: Game has to
                            be completed)
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            2. If match drawn then all the sessions will be
                            considered as lost.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Meter Fancy
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            In case match abandoned or over reduced mid point
                            rule will be applicable
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            For example: If Dhoni meter is 75 / 77 and the match
                            abandoned or over reduced, then the result will be
                            76
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            In case of single difference result will be given
                            for the higher rate of the final rate (eg 53/54) and
                            match gets abandoned then the result will be given
                            as 54
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Midpoint rule is applicable for test match also.
                            However for lambi meter/ inning meter 70 overs has
                            to be played only then the same will be considered
                            as valid
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Maximum Boundaries:-
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            If the number of fours or sixes of both the team is
                            equal, then all bets of the respective event will
                            get voided
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Khado:- Test
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Minimum 70 over has to be played by the particular
                            team only then the Khado of the team will be
                            considered as valid, else the same will be voided
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span
                            _ngcontent-ng-c3168318478
                            className="text-danger"
                          >
                            Common Market:-
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            In future, if any circumstances happens like covid
                            issue , natural disasters or any reasons series will
                            postponed or cancelled then at that moment result
                            will be given to difference of opening rate to
                            present rate.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Due to any reasons company has rights to take final
                            decisions.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3168318478>
                        <td _ngcontent-ng-c3168318478>
                          <span _ngcontent-ng-c3168318478>
                            Common Means Only 1 Win
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </app-bookmarker>
          </div>
        </div>
      </div>
      <div
        _ngcontent-ng-c3502172659
        id="MatchOddsModal"
        tabIndex={-1}
        aria-labelledby="MatchOddsModalLabel"
        aria-hidden="true"
        className="modal fade ng-tns-c3502172659-8"
      >
        <div
          _ngcontent-ng-c3502172659
          className="modal-dialog match-details-rules ng-tns-c3502172659-8"
        >
          <div
            _ngcontent-ng-c3502172659
            className="modal-content ng-tns-c3502172659-8"
          >
            <app-match-odds
              _ngcontent-ng-c3502172659
              className="ng-tns-c3502172659-8"
              _nghost-ng-c3254903801
            >
              <div _ngcontent-ng-c3254903801 className="modal-header">
                <h5
                  _ngcontent-ng-c3254903801
                  id="ResultModalLabel"
                  className="modal-title"
                >
                  Rules
                </h5>
                <button
                  _ngcontent-ng-c3254903801
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="btn-close"
                />
              </div>
              <div _ngcontent-ng-c3254903801 className="modal-body p-3">
                <div _ngcontent-ng-c3254903801 className="card-header mb-3">
                  <div _ngcontent-ng-c3254903801 className="card-title">
                    {" "}
                    MATCH ODDS{" "}
                  </div>
                </div>
                <div _ngcontent-ng-c3254903801 className="rules-table-design">
                  <table
                    _ngcontent-ng-c3254903801
                    className="table table-bordered w-100"
                  >
                    <tbody _ngcontent-ng-c3254903801>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span
                            _ngcontent-ng-c3254903801
                            className="text-danger"
                          >
                            1. Even odd game betting rate rules.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            1.1 Completed game is valid , in case due to rain
                            over are reduced or match abandoned particular game
                            will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            1.2 All bets regarding to ODD/EVEN
                            player/partnership are valid if one legal delivery
                            is being played, else the bets will be deleted.
                            Player odd/even all advance bets will be valid if
                            one legal delivery is being played in match
                            otherwise voided.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            1.3 All bets regarding Odd/Even sessions will be
                            deleted if the particular session is incomplete, for
                            example match got abandoned or finished before the
                            end of particular session.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            1.4 In any circumstances management decision will be
                            final.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span
                            _ngcontent-ng-c3254903801
                            className="text-danger"
                          >
                            2 Top batsman rules:-
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span
                            _ngcontent-ng-c3254903801
                            className="text-danger"
                          >
                            2.1 If any player does not come as per playing
                            eleven then all bets will be get deleted for the
                            particular player.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            2.2 two players done the same run in a single match
                            (M Agarwal 30 runs and A Rayudu 30 runs, whole
                            inning top batsmen score also 30 run) then both
                            player settlement to be get done 50 percent (50% ,
                            50%)rate on their original value which given by our
                            exchange.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            Suppose we have opened value of M Agarwal 3.75 back
                            and customer place bets on 10000 @ 3.75 rates and A
                            Rayudu 3.0 back and customer place bets on 10000 @
                            3.0 rates.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            Whole inning result announces 30 run by both player
                            then
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span
                            _ngcontent-ng-c3254903801
                            className="text-danger"
                          >
                            Rule of top batsman:-if you bet on M Agarwal you
                            will be get half amount of this rate
                            (10000*3.75/2=18750 you will get)
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span
                            _ngcontent-ng-c3254903801
                            className="text-danger"
                          >
                            Rule of top batsman:-if you bet on A Rayudu you will
                            be get half amount of this rate (10000*3.00/2=15000
                            you will get)
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            Top batsman only 1st inning valid.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            For one day 50 over and for t-20 match 20 over must
                            be played for top batsmen otherwise all bets will be
                            deleted.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span
                            _ngcontent-ng-c3254903801
                            className="text-danger"
                          >
                            Man of the Match Rules
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            1. All bets will be deleted in case the match is
                            abandoned or over reduced.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            2. All bets will be deleted if the mentioned player
                            is not included in playing 11.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            3. In case Man of the Match is shared between two
                            players then Dead heat rule will be applicable, For
                            example K Perera and T Iqbal shares the Man of the
                            Match, then the settlement will be done 50% of the
                            rates accordingly.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            4. Rules similar to our Top Batsman rules.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span
                            _ngcontent-ng-c3254903801
                            className="text-danger"
                          >
                            Maximum Sixes by Team
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            1. All bets will be deleted if match abandoned or
                            over reduced
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            2. All bets will be deleted if both the teams hits
                            same number of sixes.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            3. Super over will not be considered.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span
                            _ngcontent-ng-c3254903801
                            className="text-danger"
                          >
                            Maximum 6 or 10 over runs
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            1. All bets will be deleted if match abandoned or
                            over reduced.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            2. All the bets will be deleted if both the teams
                            score is same (Runs scored in 6 or 10 overs)
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            3. 6 overs for T20 and 10 overs for ODI
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            4. Both the innings are valid.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            5. This fancy will be valid for 1st 6 overs of both
                            innings for T20 and 1st 10 overs of both innings for
                            ODI
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span
                            _ngcontent-ng-c3254903801
                            className="text-danger"
                          >
                            Batsman Match
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            Batsman Match:- Bets for Favourite batsman from the
                            two batsman matched.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            All bets will be deleted if any one of the mentioned
                            player is not included in playing 11.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            All bets will be deleted unless one ball being
                            played by both the mentioned players.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            All bets will be deleted if over reduced or Match
                            abandoned.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            All bets will be deleted if both the player scored
                            same run. For example H Amla and J Bairstow are the
                            batsman matched, H Amla and J Bairstow both scored
                            38 runs then all bets will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            Both innings will be valid
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span
                            _ngcontent-ng-c3254903801
                            className="text-danger"
                          >
                            Opening Pair
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            1. Bets for Favourite opening pair from the two
                            mentioned opening pair.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            2. Runs made by both the opening player will be
                            added. For example:- J Roy scored 20 runs and J
                            Bairstow scored 30 runs result will be 50 runs.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            3. Highest run made by the pair will be declared as
                            winner. For example: Opening pair ENG total is 70
                            runs and Opening pair SA is 90 runs, then SA 90 runs
                            will be declared as winner.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            Both innings will be valid
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span
                            _ngcontent-ng-c3254903801
                            className="text-danger"
                          >
                            Our exchange Special
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            All bets will be deleted if the mentioned player is
                            not included in playing 11.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            All bets will be deleted if match abandoned or over
                            reduced.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            Both innings will be valid
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span
                            _ngcontent-ng-c3254903801
                            className="text-danger"
                          >
                            Direction of First Boundary
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            All bets will be deleted if the mentioned batsman is
                            not included in playing 11.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            All bets will be deleted if match abandoned or over
                            reduced.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            The boundary hit through off side of the stump will
                            be considered as off side four.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            The boundary hit through leg side of the stump will
                            be considered as leg side four.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            Boundaries through extras (byes,leg
                            byes,wide,overthrow) will not be considered as
                            valid.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            Only 1st Inning will be considered
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span
                            _ngcontent-ng-c3254903801
                            className="text-danger"
                          >
                            Fifty &amp; Century by Batsman
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            All bets will be deleted if match abandoned or over
                            reduced.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            All bets will be deleted if the mentioned batsman is
                            not included in playing 11.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            All bets will be deleted unless the batsman faces
                            one legal ball.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            Both Innings will be valid.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span
                            _ngcontent-ng-c3254903801
                            className="text-danger"
                          >
                            1st over Fancy
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            Boundaries through extras (byes,leg
                            byes,wide,overthrow) will not be considered.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            Only 1st inning will be valid
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span
                            _ngcontent-ng-c3254903801
                            className="text-danger"
                          >
                            Odd Even Fancy
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            Incompleted games will be deleted. Over reduced or
                            abandoned all bets will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span
                            _ngcontent-ng-c3254903801
                            className="text-danger"
                          >
                            For example:-275 run SL bhav must be played 50 over
                            if rain comes or any condition otherwise 275 run SL
                            bets will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span
                            _ngcontent-ng-c3254903801
                            className="text-danger"
                          >
                            Next Man out
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            Next man out fancy advance &amp; in regular. Both
                            inning will be valid. If any player does not come in
                            opening then all bets will be deleted. If over
                            reduced or abandoned then all bets will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span
                            _ngcontent-ng-c3254903801
                            className="text-danger"
                          >
                            Caught out
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            Caught out fancy in advance &amp; in regular. Both
                            inning will be valid. If over reduced or match
                            abandoned then all bets will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span
                            _ngcontent-ng-c3254903801
                            className="text-danger"
                          >
                            Wkt &amp; All out Fancy
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            5 wkt in 10 over &amp; All out in 20 over fancy is
                            valid for both inning. If match abandoned or over
                            reduced all bets will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span
                            _ngcontent-ng-c3254903801
                            className="text-danger"
                          >
                            Test Match: Game Completed Fancy
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            1. This is the fancy for match to be won/ completed
                            in which day &amp; session (Completed: Game has to
                            be completed)
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            2. If match drawn then all the sessions will be
                            considered as lost.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span
                            _ngcontent-ng-c3254903801
                            className="text-danger"
                          >
                            Meter Fancy
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            In case match abandoned or over reduced mid point
                            rule will be applicable
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            For example: If Dhoni meter is 75 / 77 and the match
                            abandoned or over reduced, then the result will be
                            76
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            In case of single difference result will be given
                            for the higher rate of the final rate (eg 53/54) and
                            match gets abandoned then the result will be given
                            as 54
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            Midpoint rule is applicable for test match also.
                            However for lambi meter/ inning meter 70 overs has
                            to be played only then the same will be considered
                            as valid
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span
                            _ngcontent-ng-c3254903801
                            className="text-danger"
                          >
                            Maximum Boundaries:-
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            If the number of fours or sixes of both the team is
                            equal, then all bets of the respective event will
                            get voided
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span
                            _ngcontent-ng-c3254903801
                            className="text-danger"
                          >
                            Khado:- Test
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            Minimum 70 over has to be played by the particular
                            team only then the Khado of the team will be
                            considered as valid, else the same will be voided
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span
                            _ngcontent-ng-c3254903801
                            className="text-danger"
                          >
                            Common Market:-
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            In future, if any circumstances happens like covid
                            issue , natural disasters or any reasons series will
                            postponed or cancelled then at that moment result
                            will be given to difference of opening rate to
                            present rate.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            Due to any reasons company has rights to take final
                            decisions.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3254903801>
                        <td _ngcontent-ng-c3254903801>
                          <span _ngcontent-ng-c3254903801>
                            Common Means Only 1 Win
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </app-match-odds>
          </div>
        </div>
      </div>
      <div
        _ngcontent-ng-c3502172659
        id="FancyModal"
        tabIndex={-1}
        aria-labelledby="FancyModalLabel"
        aria-hidden="true"
        className="modal fade ng-tns-c3502172659-8"
      >
        <div
          _ngcontent-ng-c3502172659
          className="modal-dialog match-details-rules ng-tns-c3502172659-8"
        >
          <div
            _ngcontent-ng-c3502172659
            className="modal-content ng-tns-c3502172659-8"
          >
            <app-fancy-rules
              _ngcontent-ng-c3502172659
              className="ng-tns-c3502172659-8"
              _nghost-ng-c3779754666
            >
              <div _ngcontent-ng-c3779754666 className="modal-header">
                <h5
                  _ngcontent-ng-c3779754666
                  id="ResultModalLabel"
                  className="modal-title"
                >
                  Rules
                </h5>
                <button
                  _ngcontent-ng-c3779754666
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="btn-close"
                />
              </div>
              <div _ngcontent-ng-c3779754666 className="modal-body p-3">
                <div _ngcontent-ng-c3779754666 className="card-header mb-3">
                  <div _ngcontent-ng-c3779754666 className="card-title">
                    {" "}
                    SESSIONS{" "}
                  </div>
                </div>
                <div _ngcontent-ng-c3779754666 className="rules-table-design">
                  <table
                    _ngcontent-ng-c3779754666
                    className="table table-bordered w-100"
                  >
                    <tbody _ngcontent-ng-c3779754666>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span
                            _ngcontent-ng-c3779754666
                            className="text-danger"
                          >
                            1. Even odd game betting rate rules.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            1.1 Completed game is valid , in case due to rain
                            over are reduced or match abandoned particular game
                            will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            1.2 All bets regarding to ODD/EVEN
                            player/partnership are valid if one legal delivery
                            is being played, else the bets will be deleted.
                            Player odd/even all advance bets will be valid if
                            one legal delivery is being played in match
                            otherwise voided.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            1.3 All bets regarding Odd/Even sessions will be
                            deleted if the particular session is incomplete, for
                            example match got abandoned or finished before the
                            end of particular session.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            1.4 In any circumstances management decision will be
                            final.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span
                            _ngcontent-ng-c3779754666
                            className="text-danger"
                          >
                            2 Top batsman rules:-
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span
                            _ngcontent-ng-c3779754666
                            className="text-danger"
                          >
                            2.1 If any player does not come as per playing
                            eleven then all bets will be get deleted for the
                            particular player.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            2.2 two players done the same run in a single match
                            (M Agarwal 30 runs and A Rayudu 30 runs, whole
                            inning top batsmen score also 30 run) then both
                            player settlement to be get done 50 percent (50% ,
                            50%)rate on their original value which given by our
                            exchange.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            Suppose we have opened value of M Agarwal 3.75 back
                            and customer place bets on 10000 @ 3.75 rates and A
                            Rayudu 3.0 back and customer place bets on 10000 @
                            3.0 rates.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            Whole inning result announces 30 run by both player
                            then
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span
                            _ngcontent-ng-c3779754666
                            className="text-danger"
                          >
                            Rule of top batsman:-if you bet on M Agarwal you
                            will be get half amount of this rate
                            (10000*3.75/2=18750 you will get)
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span
                            _ngcontent-ng-c3779754666
                            className="text-danger"
                          >
                            Rule of top batsman:-if you bet on A Rayudu you will
                            be get half amount of this rate (10000*3.00/2=15000
                            you will get)
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            Top batsman only 1st inning valid.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            For one day 50 over and for t-20 match 20 over must
                            be played for top batsmen otherwise all bets will be
                            deleted.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span
                            _ngcontent-ng-c3779754666
                            className="text-danger"
                          >
                            Man of the Match Rules
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            1. All bets will be deleted in case the match is
                            abandoned or over reduced.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            2. All bets will be deleted if the mentioned player
                            is not included in playing 11.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            3. In case Man of the Match is shared between two
                            players then Dead heat rule will be applicable, For
                            example K Perera and T Iqbal shares the Man of the
                            Match, then the settlement will be done 50% of the
                            rates accordingly.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            4. Rules similar to our Top Batsman rules.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span
                            _ngcontent-ng-c3779754666
                            className="text-danger"
                          >
                            Maximum Sixes by Team
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            1. All bets will be deleted if match abandoned or
                            over reduced
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            2. All bets will be deleted if both the teams hits
                            same number of sixes.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            3. Super over will not be considered.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span
                            _ngcontent-ng-c3779754666
                            className="text-danger"
                          >
                            Maximum 6 or 10 over runs
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            1. All bets will be deleted if match abandoned or
                            over reduced.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            2. All the bets will be deleted if both the teams
                            score is same (Runs scored in 6 or 10 overs)
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            3. 6 overs for T20 and 10 overs for ODI
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            4. Both the innings are valid.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            5. This fancy will be valid for 1st 6 overs of both
                            innings for T20 and 1st 10 overs of both innings for
                            ODI
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span
                            _ngcontent-ng-c3779754666
                            className="text-danger"
                          >
                            Batsman Match
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            Batsman Match:- Bets for Favourite batsman from the
                            two batsman matched.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            All bets will be deleted if any one of the mentioned
                            player is not included in playing 11.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            All bets will be deleted unless one ball being
                            played by both the mentioned players.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            All bets will be deleted if over reduced or Match
                            abandoned.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            All bets will be deleted if both the player scored
                            same run. For example H Amla and J Bairstow are the
                            batsman matched, H Amla and J Bairstow both scored
                            38 runs then all bets will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            Both innings will be valid
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span
                            _ngcontent-ng-c3779754666
                            className="text-danger"
                          >
                            Opening Pair
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            1. Bets for Favourite opening pair from the two
                            mentioned opening pair.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            2. Runs made by both the opening player will be
                            added. For example:- J Roy scored 20 runs and J
                            Bairstow scored 30 runs result will be 50 runs.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            3. Highest run made by the pair will be declared as
                            winner. For example: Opening pair ENG total is 70
                            runs and Opening pair SA is 90 runs, then SA 90 runs
                            will be declared as winner.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            Both innings will be valid
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span
                            _ngcontent-ng-c3779754666
                            className="text-danger"
                          >
                            Our exchange Special
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            All bets will be deleted if the mentioned player is
                            not included in playing 11.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            All bets will be deleted if match abandoned or over
                            reduced.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            Both innings will be valid
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span
                            _ngcontent-ng-c3779754666
                            className="text-danger"
                          >
                            Direction of First Boundary
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            All bets will be deleted if the mentioned batsman is
                            not included in playing 11.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            All bets will be deleted if match abandoned or over
                            reduced.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            The boundary hit through off side of the stump will
                            be considered as off side four.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            The boundary hit through leg side of the stump will
                            be considered as leg side four.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            Boundaries through extras (byes,leg
                            byes,wide,overthrow) will not be considered as
                            valid.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            Only 1st Inning will be considered
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span
                            _ngcontent-ng-c3779754666
                            className="text-danger"
                          >
                            Fifty &amp; Century by Batsman
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            All bets will be deleted if match abandoned or over
                            reduced.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            All bets will be deleted if the mentioned batsman is
                            not included in playing 11.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            All bets will be deleted unless the batsman faces
                            one legal ball.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            Both Innings will be valid.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span
                            _ngcontent-ng-c3779754666
                            className="text-danger"
                          >
                            1st over Fancy
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            Boundaries through extras (byes,leg
                            byes,wide,overthrow) will not be considered.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            Only 1st inning will be valid
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span
                            _ngcontent-ng-c3779754666
                            className="text-danger"
                          >
                            Odd Even Fancy
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            Incompleted games will be deleted. Over reduced or
                            abandoned all bets will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span
                            _ngcontent-ng-c3779754666
                            className="text-danger"
                          >
                            For example:-275 run SL bhav must be played 50 over
                            if rain comes or any condition otherwise 275 run SL
                            bets will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span
                            _ngcontent-ng-c3779754666
                            className="text-danger"
                          >
                            Next Man out
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            Next man out fancy advance &amp; in regular. Both
                            inning will be valid. If any player does not come in
                            opening then all bets will be deleted. If over
                            reduced or abandoned then all bets will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span
                            _ngcontent-ng-c3779754666
                            className="text-danger"
                          >
                            Caught out
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            Caught out fancy in advance &amp; in regular. Both
                            inning will be valid. If over reduced or match
                            abandoned then all bets will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span
                            _ngcontent-ng-c3779754666
                            className="text-danger"
                          >
                            Wkt &amp; All out Fancy
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            5 wkt in 10 over &amp; All out in 20 over fancy is
                            valid for both inning. If match abandoned or over
                            reduced all bets will be deleted.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span
                            _ngcontent-ng-c3779754666
                            className="text-danger"
                          >
                            Test Match: Game Completed Fancy
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            1. This is the fancy for match to be won/ completed
                            in which day &amp; session (Completed: Game has to
                            be completed)
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            2. If match drawn then all the sessions will be
                            considered as lost.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span
                            _ngcontent-ng-c3779754666
                            className="text-danger"
                          >
                            Meter Fancy
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            In case match abandoned or over reduced mid point
                            rule will be applicable
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            For example: If Dhoni meter is 75 / 77 and the match
                            abandoned or over reduced, then the result will be
                            76
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            In case of single difference result will be given
                            for the higher rate of the final rate (eg 53/54) and
                            match gets abandoned then the result will be given
                            as 54
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            Midpoint rule is applicable for test match also.
                            However for lambi meter/ inning meter 70 overs has
                            to be played only then the same will be considered
                            as valid
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span
                            _ngcontent-ng-c3779754666
                            className="text-danger"
                          >
                            Maximum Boundaries:-
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            If the number of fours or sixes of both the team is
                            equal, then all bets of the respective event will
                            get voided
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span
                            _ngcontent-ng-c3779754666
                            className="text-danger"
                          >
                            Khado:- Test
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            Minimum 70 over has to be played by the particular
                            team only then the Khado of the team will be
                            considered as valid, else the same will be voided
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span
                            _ngcontent-ng-c3779754666
                            className="text-danger"
                          >
                            Common Market:-
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            In future, if any circumstances happens like covid
                            issue , natural disasters or any reasons series will
                            postponed or cancelled then at that moment result
                            will be given to difference of opening rate to
                            present rate.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            Due to any reasons company has rights to take final
                            decisions.
                          </span>
                        </td>
                      </tr>
                      <tr _ngcontent-ng-c3779754666>
                        <td _ngcontent-ng-c3779754666>
                          <span _ngcontent-ng-c3779754666>
                            Common Means Only 1 Win
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </app-fancy-rules>
          </div>
        </div>
      </div>
      <div
        _ngcontent-ng-c3502172659
        id="LastResultModal"
        tabIndex={-1}
        aria-labelledby="LastResultModalLabel"
        aria-hidden="true"
        className="modal fade result-modal ng-tns-c3502172659-8"
      >
        <app-last-result-modal
          _ngcontent-ng-c3502172659
          className="ng-tns-c3502172659-8"
          _nghost-ng-c88570608
        >
          <div _ngcontent-ng-c88570608 className="modal-dialog modal-lg">
            <div _ngcontent-ng-c88570608 className="modal-content">
              <div _ngcontent-ng-c88570608 className="modal-header">
                <h5
                  _ngcontent-ng-c88570608
                  id="LastResultModalLabel"
                  className="modal-title"
                />
                <button
                  _ngcontent-ng-c88570608
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="btn-close"
                />
              </div>
              <div
                _ngcontent-ng-c88570608
                className="Baccarat2result modal-body"
              >
                {/**/}
              </div>
            </div>
          </div>
        </app-last-result-modal>
      </div>
    </app-casino-match-detail>
  );
}

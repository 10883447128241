import React from "react";
import { Helmet } from "react-helmet";

export default function Admin() {
  return (
    <>
      <Helmet>
        <title>Admin</title>
        <meta name="description" content="Admin" />
        <meta name="viewport" content="width=1024" />
      </Helmet>
      <iframe
        style={{
          height: "100vh",
        }}
        width="100%"
        src={`https://admin.${window.location.hostname}`}
      />
    </>
  );
}

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter,
  Route,
  Router,
  Routes,
  useLocation,
} from "react-router-dom";

import ActivityLog from "./pages/activityLog/ActivityLog";
import BetHistory from "./pages/betHistory/BetHistory";
import CasinoResult from "./pages/casinoResults/CasinoResults";
import Footer from "./includes/footer";
import ProfitLossReport from "./pages/profitLossReport/ProfitLossReport";
import UnSetteledBet from "./pages/unSetteledBet/UnSetteledBet";
import store, { saveState } from "./redux/store";
import { useState } from "react";
import MobileHome from "./pages/home/index-m";

import PrivateOutletM from "./layout/PrivateOutlet";
import TennisGameM from "./pages/game/tennis/TennisGameM";
import SoccerGameM from "./pages/game/soccer/SoccerGameM";
import CricketGameM from "./pages/game/cricket/CricketGameM";
import AccountStatementM from "./pages/accountStatement/AccountStatementM";
import PublicOutletM from "./layout/PublicOutletM";
import ChangeButtonValue from "./pages/changeButtonValue";
import ChangePassword from "./pages/ChangePassword";
import SuperOver from "./pages/casino/SuperOver";
import Race20 from "./pages/casino/Race20";
import FiveCricket from "./pages/casino/FiveCricket";
import AndarBahar2 from "./pages/casino/AndarBahar2";
import DragonTiger2 from "./pages/casino/DragonTiger2";
import Baccarat2 from "./pages/casino/Baccarat2";
import Baccarat from "./pages/casino/Baccarat";
import BallByBall from "./pages/casino/BallByBall";
import Lucky7A from "./pages/casino/Lucky7A";
import Lucky7B from "./pages/casino/Lucky7B";
import CasinoQueen from "./pages/casino/CasinoQueen";
import AmarAkhbarAnthoany from "./pages/casino/AmarAkhbarAnthoany";
import TeenpattiOneDay from "./pages/casino/TeenpattiOneDay";
import { QueryClient, QueryClientProvider } from "react-query";
import DragonTiger from "./pages/casino/DragonTiger";
import InitialRule from "./components/InitialRule";
import SecurityAuth from "./pages/securityAuth/SecurityAuth";
import Otp from "./layout/Otp";
import { Toaster } from "react-hot-toast";
import ResponsibleGaming from "./pages/ResponsibleGaming";
import TermsAndCondition from "./pages/TermsAndCondition";
import Home from "./pages/home";
import CricketGame from "./pages/game/cricket/CricketGames";
import Header from "./includes/header";
import SoccerGame from "./pages/game/soccer/SoccerGame";
import TennisGame from "./pages/game/tennis/TennisGame";
import Admin from "./pages/Admin";
export default function App() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.account.userData);
  const isLoading = useSelector((state) => state.account.loading);
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [queryClient] = useState(() => new QueryClient());
  useEffect(() => {
    store.subscribe(() => {
      saveState(store.getState());
    });
  }, [0]);
  const isAdmin = location.pathname.includes("admin");

  return (
    <QueryClientProvider client={queryClient}>
      <Toaster />
      {userData?.token && userData?.resetRequired ? (
        <PrivateOutletM>
          <Routes>
            <Route path="/" element={<ChangePassword />} />
            <Route path="/admin" element={<Admin />} />
          </Routes>
        </PrivateOutletM>
      ) : userData.token ? (
        <>
          {!isAdmin && <Header />}
          <PrivateOutletM>
            {!isAdmin && <InitialRule />}

            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/home" element={<Home />} />
              <Route exact path="/sports" element={<Home />} />
              <Route
                exact
                path="/responsible-gaming"
                element={<ResponsibleGaming />}
              />
              <Route exact path="/game/soccer/:id" element={<SoccerGame />} />
              <Route exact path="/game/cricket/:id" element={<CricketGame />} />
              <Route exact path="/game/tennis/:id" element={<TennisGame />} />
              <Route
                path="/reports/accountstatement"
                element={<AccountStatementM />}
              />
              <Route path="/reports/bethistory" element={<BetHistory />} />
              <Route
                path="/reports/profitloss"
                element={<ProfitLossReport />}
              />
              <Route
                path="/reports/unsetteledbet"
                element={<UnSetteledBet />}
              />
              <Route
                exact
                path="/terms-and-conditions"
                element={<TermsAndCondition />}
              />
              <Route path="/reports/activity-log" element={<ActivityLog />} />
              <Route path="/reports/casinoresults" element={<CasinoResult />} />

              <Route
                path="/setting/changebtnvalue"
                element={<ChangeButtonValue />}
              />
              <Route
                path="/settings/security-auth"
                element={<SecurityAuth />}
              />
              <Route
                path="/setting/changepassword"
                element={<ChangePassword />}
              />
              <Route path="/casino/superover" element={<SuperOver />} />
              <Route path="/casino/race20" element={<Race20 />} />
              <Route path="/casino/cricketv3" element={<FiveCricket />} />
              <Route path="/casino/ab20" element={<AndarBahar2 />} />
              <Route path="/casino/dt202" element={<DragonTiger2 />} />
              <Route path="/casino/dt20" element={<DragonTiger />} />
              <Route path="/casino/baccarat2" element={<Baccarat2 />} />
              <Route path="/casino/baccarat" element={<Baccarat />} />
              <Route path="/casino/ball-by-ball" element={<BallByBall />} />
              <Route path="/casino/lucky7eu" element={<Lucky7B />} />
              <Route path="/casino/lucky7" element={<Lucky7A />} />
              <Route path="/casino/casino-queen" element={<CasinoQueen />} />
              <Route path="/casino/aaa" element={<AmarAkhbarAnthoany />} />
              <Route
                path="/casino/teenpatti/oneday"
                element={<TeenpattiOneDay />}
              />
              <Route path="/admin" element={<Admin />} />
            </Routes>

            {!isAdmin && <Footer />}
          </PrivateOutletM>
        </>
      ) : (
        <Routes>
          <Route path="/admin" element={<Admin />} />
          <Route path="/" element={<PublicOutletM />} />
          <Route path="/otp" element={<Otp />} />
          <Route
            exact
            path="/responsible-gaming"
            element={<ResponsibleGaming />}
          />
          <Route
            exact
            path="/terms-and-conditions"
            element={<TermsAndCondition />}
          />
        </Routes>
      )}
    </QueryClientProvider>
  );
}

import React from "react";
import "./index.scoped.css";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import {
  getCasinoBetsAPI,
  getCasinoLastTenResultAPI,
  getCasinoOddsAPI,
} from "../../service/casino";
import { CASINO_VIDEO, getOddForEntity, setBet } from "./CasinoGeneric";
import PlaceBet from "./components/PlaceBet";
import CasinoVideo from "./components/CasinoVideo";
import PlacedBet from "./components/PlacedBet";
import Timer from "./components/Timer";
export default function Race20() {
  const GAME_ID = "RACE20";
  const VIDEO_ID = 3056;
  const dispatch = useDispatch();
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );
  const lastTenResultData = useQuery(
    "getResult",
    () => getCasinoLastTenResultAPI(GAME_ID),
    {
      refetchInterval: 1500,
    }
  );
  const casinoBets = useQuery("casinoBets", () => getCasinoBetsAPI(GAME_ID), {
    refetchInterval: 2500,
  });
  const [selectedTab, setSelectedTab] = React.useState("game");
  return (
    <app-casino-match-detail
      _nghost-ng-c3502172659
      className="ng-tns-c3502172659-2 ng-star-inserted"
    >
      <div className="main-container ng-tns-c3502172659-2">
        <div className="center-main-container casino-page ng-tns-c3502172659-2 ng-star-inserted">
          <div className="center-container ng-tns-c3502172659-2">
            <div className="casino-page-container ng-tns-c3502172659-2 race20">
              <div className="casino-title-header-mobile d-xl-none ng-tns-c3502172659-2">
                <ul className="nav nav-tabs menu-tabs ng-tns-c3502172659-2">
                  <li
                    onClick={() => setSelectedTab("game")}
                    className="nav-item ng-tns-c3502172659-2"
                  >
                    <div className="ng-tns-c3502172659-2 active nav-link">
                      Game
                    </div>
                  </li>
                  <li
                    onClick={() => setSelectedTab("placed")}
                    className="nav-item ng-tns-c3502172659-2"
                  >
                    <div className="ng-tns-c3502172659-2 nav-link">
                      Placed Bet ({casinoBets?.data?.response?.length}){" "}
                    </div>
                  </li>
                </ul>
                <div className="pe-2 ng-tns-c3502172659-2">
                  <span className="casino-rid ng-tns-c3502172659-2">
                    <a className="d-block ng-tns-c3502172659-2">
                      <small className="ng-tns-c3502172659-2">Rules</small>
                    </a>{" "}
                    Round ID:{" "}
                    <span className="ng-tns-c3502172659-2 ng-star-inserted">
                      {casinoData?.data?.response?.marketId}
                    </span>
                  </span>
                </div>
              </div>
              <div className="casino-header ng-tns-c3502172659-2">
                <span className="casino-name ng-tns-c3502172659-2">
                  Race 20 20
                  <a className="ms-1 d-xl-inline d-none ng-tns-c3502172659-2">
                    <small className="ng-tns-c3502172659-2">Rules</small>
                  </a>
                </span>
                <span className="casino-rid d-none d-xl-inline-block ng-tns-c3502172659-2">
                  <small className="ng-tns-c3502172659-2">
                    Round ID:{" "}
                    <span className="ng-tns-c3502172659-2 ng-star-inserted">
                      46.2409071636402222
                    </span>
                  </small>
                  <span className="ng-tns-c3502172659-2 ng-star-inserted">
                    | Min: 100 | Max: 100000
                  </span>
                </span>
              </div>

              {selectedTab == "game" ? (
                <div className="ng-tns-c3502172659-2 ng-star-inserted">
                  <div className="casino-video ng-tns-c3502172659-2">
                    <div className="video-box-container ng-tns-c3502172659-2">
                      <div className="casino-video-box ng-tns-c3502172659-2 ng-star-inserted">
                        <CasinoVideo id={CASINO_VIDEO.race2020} />
                      </div>
                    </div>

                    <Timer value={casinoData?.data?.response?.autoTime} />
                  </div>

                  <div className="casino-detail ng-tns-c3502172659-2">
                    <div className="casino-detail ng-tns-c3502172659-2 ng-star-inserted">
                      <div className="casino-table ng-tns-c3502172659-2">
                        <div className="casino-table-box casino-table-box_bg ng-tns-c3502172659-2">
                          <div className="casino-odd-box-container ng-tns-c3502172659-2 ng-star-inserted">
                            <div className="casino-nation-name ng-tns-c3502172659-2">
                              <img
                                className="ng-tns-c3502172659-2 ng-star-inserted"
                                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/KHH.webp"
                              />

                              <div className="min_max_collapse ms-2 ng-tns-c3502172659-2">
                                <a
                                  data-bs-toggle="collapse"
                                  role="button"
                                  aria-expanded="false"
                                  className="minmax-btn collapsed ng-tns-c3502172659-2"
                                  href="#collapserange2r20201"
                                  aria-controls="collapserange21"
                                >
                                  <i className="fas fa-info-circle ng-tns-c3502172659-2" />
                                </a>
                                <div
                                  className="range-collpase collapse ng-tns-c3502172659-2"
                                  id="collapserange2r20201"
                                >
                                  <span className="min_max_span ms-0 ng-tns-c3502172659-2">
                                    <b className="ng-tns-c3502172659-2">Min:</b>
                                    <span className="ng-tns-c3502172659-2">
                                      100
                                    </span>
                                  </span>
                                  <span className="min_max_span ms-1 ng-tns-c3502172659-2">
                                    <b className="ng-tns-c3502172659-2">Max:</b>
                                    <span className="ng-tns-c3502172659-2">
                                      100000
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              className={`ng-tns-c3502172659-2 casinobox-row ${casinoData
                                ?.data?.response?.autoTime < 5 &&
                                "suspended-box"}`}
                            >
                              <div
                                onClick={() =>
                                  setBet("k of heart", casinoData, dispatch)
                                }
                                className="casino-odds-box back ng-tns-c3502172659-2"
                              >
                                <span className="casino-odds ng-tns-c3502172659-2">
                                  {getOddForEntity("k of spade", casinoData)}{" "}
                                </span>
                                <div className="casino-volume ng-tns-c3502172659-2">
                                  {getOddForEntity(
                                    "k of heart",
                                    casinoData,
                                    true,
                                    true
                                  )}{" "}
                                </div>
                              </div>
                              <div
                                onClick={() =>
                                  setBet(
                                    "k of heart",
                                    casinoData,
                                    dispatch,
                                    "lay"
                                  )
                                }
                                className="casino-odds-box lay ng-tns-c3502172659-2"
                              >
                                <span className="casino-odds ng-tns-c3502172659-2">
                                  {getOddForEntity(
                                    "k of spade",
                                    casinoData,
                                    false
                                  )}{" "}
                                </span>
                                <div className="casino-volume ng-tns-c3502172659-2">
                                  {getOddForEntity(
                                    "k of heart",
                                    casinoData,
                                    false,
                                    true
                                  )}{" "}
                                </div>
                              </div>
                            </div>
                            <div className="casino-nation-book text-center w-100 ng-tns-c3502172659-2">
                              0{" "}
                            </div>
                          </div>
                          <div className="casino-odd-box-container ng-tns-c3502172659-2 ng-star-inserted">
                            <div className="casino-nation-name ng-tns-c3502172659-2">
                              <img
                                className="ng-tns-c3502172659-2 ng-star-inserted"
                                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/KDD.webp"
                              />

                              <div className="min_max_collapse ms-2 ng-tns-c3502172659-2">
                                <a
                                  data-bs-toggle="collapse"
                                  role="button"
                                  aria-expanded="false"
                                  className="minmax-btn collapsed ng-tns-c3502172659-2"
                                  href="#collapserange2r20202"
                                  aria-controls="collapserange22"
                                >
                                  <i className="fas fa-info-circle ng-tns-c3502172659-2" />
                                </a>
                                <div
                                  className="range-collpase collapse ng-tns-c3502172659-2"
                                  id="collapserange2r20202"
                                >
                                  <span className="min_max_span ms-0 ng-tns-c3502172659-2">
                                    <b className="ng-tns-c3502172659-2">Min:</b>
                                    <span className="ng-tns-c3502172659-2">
                                      100
                                    </span>
                                  </span>
                                  <span className="min_max_span ms-1 ng-tns-c3502172659-2">
                                    <b className="ng-tns-c3502172659-2">Max:</b>
                                    <span className="ng-tns-c3502172659-2">
                                      100000
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              className={`ng-tns-c3502172659-2 casinobox-row ${casinoData
                                ?.data?.response?.autoTime < 5 &&
                                "suspended-box"}`}
                            >
                              <div
                                onClick={() =>
                                  setBet("k of diamond", casinoData, dispatch)
                                }
                                className="casino-odds-box back ng-tns-c3502172659-2"
                              >
                                <span className="casino-odds ng-tns-c3502172659-2">
                                  {getOddForEntity("k of heart", casinoData)}{" "}
                                </span>
                                <div className="casino-volume ng-tns-c3502172659-2">
                                  {getOddForEntity(
                                    "k of heart",
                                    casinoData,
                                    true,
                                    true
                                  )}{" "}
                                </div>
                              </div>
                              <div
                                onClick={() =>
                                  setBet(
                                    "k of heart",
                                    casinoData,
                                    dispatch,
                                    "lay"
                                  )
                                }
                                className="casino-odds-box lay ng-tns-c3502172659-2"
                              >
                                <span className="casino-odds ng-tns-c3502172659-2">
                                  {getOddForEntity(
                                    "k of heart",
                                    casinoData,
                                    false
                                  )}{" "}
                                </span>
                                <div className="casino-volume ng-tns-c3502172659-2">
                                  {getOddForEntity(
                                    "k of heart",
                                    casinoData,
                                    false,
                                    true
                                  )}{" "}
                                </div>
                              </div>
                            </div>
                            <div className="casino-nation-book text-center w-100 ng-tns-c3502172659-2">
                              0{" "}
                            </div>
                          </div>
                          <div className="casino-odd-box-container ng-tns-c3502172659-2 ng-star-inserted">
                            <div className="casino-nation-name ng-tns-c3502172659-2">
                              <img
                                className="ng-tns-c3502172659-2 ng-star-inserted"
                                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/KCC.webp"
                              />

                              <div className="min_max_collapse ms-2 ng-tns-c3502172659-2">
                                <a
                                  data-bs-toggle="collapse"
                                  role="button"
                                  aria-expanded="false"
                                  className="minmax-btn collapsed ng-tns-c3502172659-2"
                                  href="#collapserange2r20203"
                                  aria-controls="collapserange23"
                                >
                                  <i className="fas fa-info-circle ng-tns-c3502172659-2" />
                                </a>
                                <div
                                  className="range-collpase collapse ng-tns-c3502172659-2"
                                  id="collapserange2r20203"
                                >
                                  <span className="min_max_span ms-0 ng-tns-c3502172659-2">
                                    <b className="ng-tns-c3502172659-2">Min:</b>
                                    <span className="ng-tns-c3502172659-2">
                                      100
                                    </span>
                                  </span>
                                  <span className="min_max_span ms-1 ng-tns-c3502172659-2">
                                    <b className="ng-tns-c3502172659-2">Max:</b>
                                    <span className="ng-tns-c3502172659-2">
                                      100000
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              className={`ng-tns-c3502172659-2 casinobox-row ${casinoData
                                ?.data?.response?.autoTime < 5 &&
                                "suspended-box"}`}
                            >
                              <div
                                onClick={() =>
                                  setBet("k of club", casinoData, dispatch)
                                }
                                className="casino-odds-box back ng-tns-c3502172659-2"
                              >
                                <span className="casino-odds ng-tns-c3502172659-2">
                                  {getOddForEntity("k of club", casinoData)}{" "}
                                </span>
                                <div className="casino-volume ng-tns-c3502172659-2">
                                  {getOddForEntity(
                                    "k of club",
                                    casinoData,
                                    true,
                                    true
                                  )}{" "}
                                </div>
                              </div>
                              <div
                                onClick={() =>
                                  setBet(
                                    "k of club",
                                    casinoData,
                                    dispatch,
                                    "lay"
                                  )
                                }
                                className="casino-odds-box lay ng-tns-c3502172659-2"
                              >
                                <span className="casino-odds ng-tns-c3502172659-2">
                                  {getOddForEntity(
                                    "k of club",
                                    casinoData,
                                    false
                                  )}{" "}
                                </span>
                                <div className="casino-volume ng-tns-c3502172659-2">
                                  {getOddForEntity(
                                    "k of club",
                                    casinoData,
                                    false,
                                    true
                                  )}{" "}
                                </div>
                              </div>
                            </div>
                            <div className="casino-nation-book text-center w-100 ng-tns-c3502172659-2">
                              0{" "}
                            </div>
                          </div>
                          <div className="casino-odd-box-container ng-tns-c3502172659-2 ng-star-inserted">
                            <div className="casino-nation-name ng-tns-c3502172659-2">
                              <img
                                className="ng-tns-c3502172659-2 ng-star-inserted"
                                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/KSS.webp"
                              />

                              <div className="min_max_collapse ms-2 ng-tns-c3502172659-2">
                                <a
                                  data-bs-toggle="collapse"
                                  role="button"
                                  aria-expanded="false"
                                  className="minmax-btn collapsed ng-tns-c3502172659-2"
                                  href="#collapserange2r20204"
                                  aria-controls="collapserange24"
                                >
                                  <i className="fas fa-info-circle ng-tns-c3502172659-2" />
                                </a>
                                <div
                                  className="range-collpase collapse ng-tns-c3502172659-2"
                                  id="collapserange2r20204"
                                >
                                  <span className="min_max_span ms-0 ng-tns-c3502172659-2">
                                    <b className="ng-tns-c3502172659-2">Min:</b>
                                    <span className="ng-tns-c3502172659-2">
                                      100
                                    </span>
                                  </span>
                                  <span className="min_max_span ms-1 ng-tns-c3502172659-2">
                                    <b className="ng-tns-c3502172659-2">Max:</b>
                                    <span className="ng-tns-c3502172659-2">
                                      100000
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div
                              className={`ng-tns-c3502172659-2 casinobox-row ${casinoData
                                ?.data?.response?.autoTime < 5 &&
                                "suspended-box"}`}
                            >
                              <div
                                onClick={() =>
                                  setBet("k of spade", casinoData, dispatch)
                                }
                                className="casino-odds-box back ng-tns-c3502172659-2"
                              >
                                <span className="casino-odds ng-tns-c3502172659-2">
                                  {getOddForEntity("k of heart", casinoData)}{" "}
                                </span>
                                <div className="casino-volume ng-tns-c3502172659-2">
                                  {getOddForEntity(
                                    "k of diamond",
                                    casinoData,
                                    true,
                                    true
                                  )}{" "}
                                </div>
                              </div>
                              <div
                                onClick={() =>
                                  setBet("k of heart", casinoData, dispatch)
                                }
                                className="casino-odds-box lay ng-tns-c3502172659-2"
                              >
                                <span className="casino-odds ng-tns-c3502172659-2">
                                  {getOddForEntity(
                                    "k of heart",
                                    casinoData,
                                    false
                                  )}{" "}
                                </span>
                                <div className="casino-volume ng-tns-c3502172659-2">
                                  {getOddForEntity(
                                    "k of heart",
                                    casinoData,
                                    false,
                                    true
                                  )}{" "}
                                </div>
                              </div>
                            </div>
                            <div className="casino-nation-book text-center w-100 ng-tns-c3502172659-2">
                              0{" "}
                            </div>
                          </div>
                        </div>
                        <div className="casino-table-box mt-2 ng-tns-c3502172659-2">
                          <div className="casino-table-left-box ng-tns-c3502172659-2">
                            <div className="casino-odd-box-container ng-tns-c3502172659-2">
                              <div className="casino-nation-name ng-tns-c3502172659-2" />
                              <div className="casino-nation-name ng-tns-c3502172659-2">
                                No
                              </div>
                              <div className="casino-nation-name ng-tns-c3502172659-2">
                                Yes
                              </div>
                            </div>
                            <div className="casino-odd-box-container ng-tns-c3502172659-2">
                              <div className="casino-nation-name ng-tns-c3502172659-2">
                                Total points{" "}
                                <div className="min_max_collapse ms-2 ng-tns-c3502172659-2">
                                  <a
                                    data-bs-toggle="collapse"
                                    href="#collapserange2rac2020513"
                                    role="button"
                                    aria-expanded="false"
                                    aria-controls="collapserange2rac2020513"
                                    className="minmax-btn collapsed ng-tns-c3502172659-2"
                                  >
                                    <i className="fas fa-info-circle ng-tns-c3502172659-2" />
                                  </a>
                                  <div
                                    id="collapserange2rac2020513"
                                    className="range-collpase collapse ng-tns-c3502172659-2"
                                  >
                                    <span className="min_max_span ms-0 ng-tns-c3502172659-2">
                                      <b className="ng-tns-c3502172659-2">
                                        Min:
                                      </b>
                                      <span className="ng-tns-c3502172659-2">
                                        100
                                      </span>
                                    </span>
                                    <span className="min_max_span ms-1 ng-tns-c3502172659-2">
                                      <b className="ng-tns-c3502172659-2">
                                        Max:
                                      </b>
                                      <span className="ng-tns-c3502172659-2">
                                        100000
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                onClick={() =>
                                  setBet("Total points", casinoData, dispatch)
                                }
                                className={`ng-tns-c3502172659-2 casino-odds-box lay ${casinoData
                                  ?.data?.response?.autoTime < 5 &&
                                  " suspended-box"}`}
                              >
                                <span className="casino-odds ng-tns-c3502172659-2">
                                  {getOddForEntity("k of diamond", casinoData)}{" "}
                                </span>
                                <div className="casino-volume text-center ng-tns-c3502172659-2">
                                  {getOddForEntity(
                                    "k of diamond",
                                    casinoData,
                                    true,
                                    true
                                  )}
                                </div>
                              </div>
                              <div
                                onClick={() =>
                                  setBet("k of diamond", casinoData, dispatch)
                                }
                                className={`ng-tns-c3502172659-2 back casino-odds-box ${casinoData
                                  ?.data?.response?.autoTime < 5 &&
                                  " suspended-box"}`}
                              >
                                <span className="casino-odds ng-tns-c3502172659-2">
                                  {getOddForEntity(
                                    "k of diamond",
                                    casinoData,
                                    false
                                  )}{" "}
                                </span>
                                <div className="casino-volume text-center ng-tns-c3502172659-2">
                                  {getOddForEntity(
                                    "k of diamond",
                                    casinoData,
                                    false,
                                    true
                                  )}
                                </div>
                              </div>
                              <div className="casino-nation-book ng-tns-c3502172659-2">
                                0{" "}
                              </div>
                            </div>
                            <div className="casino-odd-box-container ng-tns-c3502172659-2">
                              <div className="casino-nation-name ng-tns-c3502172659-2" />
                              <div className="casino-nation-name ng-tns-c3502172659-2">
                                No
                              </div>
                              <div className="casino-nation-name ng-tns-c3502172659-2">
                                Yes
                              </div>
                            </div>
                            <div className="casino-odd-box-container ng-tns-c3502172659-2">
                              <div className="casino-nation-name ng-tns-c3502172659-2">
                                Total cards{" "}
                                <div className="min_max_collapse ms-2 ng-tns-c3502172659-2">
                                  <a
                                    data-bs-toggle="collapse"
                                    href="#collapserange2rac2020614"
                                    role="button"
                                    aria-expanded="false"
                                    aria-controls="collapserange2rac2020614"
                                    className="minmax-btn collapsed ng-tns-c3502172659-2"
                                  >
                                    <i className="fas fa-info-circle ng-tns-c3502172659-2" />
                                  </a>
                                  <div
                                    id="collapserange2rac2020614"
                                    className="range-collpase collapse ng-tns-c3502172659-2"
                                  >
                                    <span className="min_max_span ms-0 ng-tns-c3502172659-2">
                                      <b className="ng-tns-c3502172659-2">
                                        Min:
                                      </b>
                                      <span className="ng-tns-c3502172659-2">
                                        100
                                      </span>
                                    </span>
                                    <span className="min_max_span ms-1 ng-tns-c3502172659-2">
                                      <b className="ng-tns-c3502172659-2">
                                        Max:
                                      </b>
                                      <span className="ng-tns-c3502172659-2">
                                        100000
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                onClick={() =>
                                  setBet("Total cards", casinoData, dispatch)
                                }
                                className={`ng-tns-c3502172659-2 casino-odds-box lay ${casinoData
                                  ?.data?.response?.autoTime < 5 &&
                                  " suspended-box"}`}
                              >
                                <span className="casino-odds ng-tns-c3502172659-2">
                                  {getOddForEntity(
                                    "Total card",
                                    casinoData,
                                    false
                                  )}
                                </span>
                                <div className="casino-volume text-center ng-tns-c3502172659-2">
                                  {getOddForEntity(
                                    "Total card",
                                    casinoData,
                                    false,
                                    true
                                  )}{" "}
                                </div>
                              </div>
                              <div
                                onClick={() =>
                                  setBet("Total card", casinoData, dispatch)
                                }
                                className={`ng-tns-c3502172659-2 back casino-odds-box ${casinoData
                                  ?.data?.response?.autoTime < 5 &&
                                  " suspended-box"}`}
                              >
                                <span className="casino-odds ng-tns-c3502172659-2">
                                  {getOddForEntity("total card", casinoData)}
                                </span>
                                <div className="casino-volume text-center ng-tns-c3502172659-2">
                                  {getOddForEntity(
                                    "total card",
                                    casinoData,
                                    true,
                                    true
                                  )}{" "}
                                </div>
                              </div>
                              <div className="casino-nation-book ng-tns-c3502172659-2">
                                0{" "}
                              </div>
                            </div>
                          </div>
                          <div className="casino-table-right-box right_box_padding ng-tns-c3502172659-2">
                            <div className="casino-odd-box-container ng-tns-c3502172659-2 ng-star-inserted">
                              <div className="casino-nation-name ng-tns-c3502172659-2">
                                Win with 5{" "}
                                <div
                                  onClick={() =>
                                    setBet("Win with 5", casinoData, dispatch)
                                  }
                                  className="min_max_collapse ms-2 ng-tns-c3502172659-2"
                                >
                                  <a
                                    data-bs-toggle="collapse"
                                    role="button"
                                    aria-expanded="false"
                                    className="minmax-btn collapsed ng-tns-c3502172659-2"
                                    href="#collapserange2race207"
                                    aria-controls="collapserange2race207"
                                  >
                                    <i className="fas fa-info-circle ng-tns-c3502172659-2" />
                                  </a>
                                  <div
                                    className="range-collpase collapse ng-tns-c3502172659-2"
                                    id="collapserange2race207"
                                  >
                                    <span className="min_max_span ms-0 ng-tns-c3502172659-2">
                                      <b className="ng-tns-c3502172659-2">
                                        Min:
                                      </b>
                                      <span className="ng-tns-c3502172659-2">
                                        100
                                      </span>
                                    </span>
                                    <span className="min_max_span ms-1 ng-tns-c3502172659-2">
                                      <b className="ng-tns-c3502172659-2">
                                        Max:
                                      </b>
                                      <span className="ng-tns-c3502172659-2">
                                        <span className="ng-tns-c3502172659-2 ng-star-inserted">
                                          25000{" "}
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`ng-tns-c3502172659-2 back casino-odds-box ${casinoData
                                  ?.data?.response?.autoTime < 5 &&
                                  " suspended-box"}`}
                              >
                                <span className="casino-odds ng-tns-c3502172659-2">
                                  {getOddForEntity("win with 5", casinoData)}{" "}
                                </span>
                              </div>
                              <div className="casino-nation-book text-center w-100 ng-tns-c3502172659-2">
                                {0}{" "}
                              </div>
                            </div>
                            <div className="casino-odd-box-container ng-tns-c3502172659-2 ng-star-inserted">
                              <div className="casino-nation-name ng-tns-c3502172659-2">
                                Win with 6{" "}
                                <div
                                  onClick={() =>
                                    setBet("Win with 6", casinoData, dispatch)
                                  }
                                  className="min_max_collapse ms-2 ng-tns-c3502172659-2"
                                >
                                  <a
                                    data-bs-toggle="collapse"
                                    role="button"
                                    aria-expanded="false"
                                    className="minmax-btn collapsed ng-tns-c3502172659-2"
                                    href="#collapserange2race208"
                                    aria-controls="collapserange2race208"
                                  >
                                    <i className="fas fa-info-circle ng-tns-c3502172659-2" />
                                  </a>
                                  <div
                                    className="range-collpase collapse ng-tns-c3502172659-2"
                                    id="collapserange2race208"
                                  >
                                    <span className="min_max_span ms-0 ng-tns-c3502172659-2">
                                      <b className="ng-tns-c3502172659-2">
                                        Min:
                                      </b>
                                      <span className="ng-tns-c3502172659-2">
                                        100
                                      </span>
                                    </span>
                                    <span className="min_max_span ms-1 ng-tns-c3502172659-2">
                                      <b className="ng-tns-c3502172659-2">
                                        Max:
                                      </b>
                                      <span className="ng-tns-c3502172659-2">
                                        <span className="ng-tns-c3502172659-2 ng-star-inserted">
                                          25000{" "}
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`ng-tns-c3502172659-2 back casino-odds-box ${casinoData
                                  ?.data?.response?.autoTime < 5 &&
                                  " suspended-box"}`}
                              >
                                <span className="casino-odds ng-tns-c3502172659-2">
                                  {getOddForEntity("win with 6", casinoData)}
                                </span>
                              </div>
                              <div className="casino-nation-book text-center w-100 ng-tns-c3502172659-2">
                                0{" "}
                              </div>
                            </div>
                            <div className="casino-odd-box-container ng-tns-c3502172659-2 ng-star-inserted">
                              <div className="casino-nation-name ng-tns-c3502172659-2">
                                Win with 7{" "}
                                <div
                                  onClick={() =>
                                    setBet("Win with 7", casinoData, dispatch)
                                  }
                                  className="min_max_collapse ms-2 ng-tns-c3502172659-2"
                                >
                                  <a
                                    data-bs-toggle="collapse"
                                    role="button"
                                    aria-expanded="false"
                                    className="minmax-btn collapsed ng-tns-c3502172659-2"
                                    href="#collapserange2race209"
                                    aria-controls="collapserange2race209"
                                  >
                                    <i className="fas fa-info-circle ng-tns-c3502172659-2" />
                                  </a>
                                  <div
                                    className="range-collpase collapse ng-tns-c3502172659-2"
                                    id="collapserange2race209"
                                  >
                                    <span className="min_max_span ms-0 ng-tns-c3502172659-2">
                                      <b className="ng-tns-c3502172659-2">
                                        Min:
                                      </b>
                                      <span className="ng-tns-c3502172659-2">
                                        100
                                      </span>
                                    </span>
                                    <span className="min_max_span ms-1 ng-tns-c3502172659-2">
                                      <b className="ng-tns-c3502172659-2">
                                        Max:
                                      </b>
                                      <span className="ng-tns-c3502172659-2">
                                        <span className="ng-tns-c3502172659-2 ng-star-inserted">
                                          25000{" "}
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`ng-tns-c3502172659-2 back casino-odds-box ${casinoData
                                  ?.data?.response?.autoTime < 5 &&
                                  " suspended-box"}`}
                              >
                                <span className="casino-odds ng-tns-c3502172659-2">
                                  {getOddForEntity("win with 7", casinoData)}
                                </span>
                              </div>
                              <div className="casino-nation-book text-center w-100 ng-tns-c3502172659-2">
                                0{" "}
                              </div>
                            </div>
                            <div
                              onClick={() =>
                                setBet("Win with 8", casinoData, dispatch)
                              }
                              className="casino-odd-box-container ng-tns-c3502172659-2 ng-star-inserted"
                            >
                              <div className="casino-nation-name ng-tns-c3502172659-2">
                                Win with 15{" "}
                                <div className="min_max_collapse ms-2 ng-tns-c3502172659-2">
                                  <a
                                    data-bs-toggle="collapse"
                                    role="button"
                                    aria-expanded="false"
                                    className="minmax-btn collapsed ng-tns-c3502172659-2"
                                    href="#collapserange2race2010"
                                    aria-controls="collapserange2race2010"
                                  >
                                    <i className="fas fa-info-circle ng-tns-c3502172659-2" />
                                  </a>
                                  <div
                                    className="range-collpase collapse ng-tns-c3502172659-2"
                                    id="collapserange2race2010"
                                  >
                                    <span className="min_max_span ms-0 ng-tns-c3502172659-2">
                                      <b className="ng-tns-c3502172659-2">
                                        Min:
                                      </b>
                                      <span className="ng-tns-c3502172659-2">
                                        100
                                      </span>
                                    </span>
                                    <span className="min_max_span ms-1 ng-tns-c3502172659-2">
                                      <b className="ng-tns-c3502172659-2">
                                        Max:
                                      </b>
                                      <span className="ng-tns-c3502172659-2">
                                        <span className="ng-tns-c3502172659-2 ng-star-inserted">
                                          25000{" "}
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`ng-tns-c3502172659-2 back casino-odds-box ${casinoData
                                  ?.data?.response?.autoTime < 5 &&
                                  " suspended-box"}`}
                              >
                                <span className="casino-odds ng-tns-c3502172659-2">
                                  {getOddForEntity("Win with 15", casinoData)}
                                </span>
                              </div>
                              <div className="casino-nation-book text-center w-100 ng-tns-c3502172659-2">
                                0{" "}
                              </div>
                            </div>
                            <div className="casino-odd-box-container ng-tns-c3502172659-2 ng-star-inserted">
                              <div className="casino-nation-name ng-tns-c3502172659-2">
                                Win with 16{" "}
                                <div
                                  onClick={() =>
                                    setBet("Win with 16", casinoData, dispatch)
                                  }
                                  className="min_max_collapse ms-2 ng-tns-c3502172659-2"
                                >
                                  <a
                                    data-bs-toggle="collapse"
                                    role="button"
                                    aria-expanded="false"
                                    className="minmax-btn collapsed ng-tns-c3502172659-2"
                                    href="#collapserange2race2011"
                                    aria-controls="collapserange2race2011"
                                  >
                                    <i className="fas fa-info-circle ng-tns-c3502172659-2" />
                                  </a>
                                  <div
                                    className="range-collpase collapse ng-tns-c3502172659-2"
                                    id="collapserange2race2011"
                                  >
                                    <span className="min_max_span ms-0 ng-tns-c3502172659-2">
                                      <b className="ng-tns-c3502172659-2">
                                        Min:
                                      </b>
                                      <span className="ng-tns-c3502172659-2">
                                        100
                                      </span>
                                    </span>
                                    <span className="min_max_span ms-1 ng-tns-c3502172659-2">
                                      <b className="ng-tns-c3502172659-2">
                                        Max:
                                      </b>
                                      <span className="ng-tns-c3502172659-2">
                                        <span className="ng-tns-c3502172659-2 ng-star-inserted">
                                          25000{" "}
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`ng-tns-c3502172659-2 back casino-odds-box ${casinoData
                                  ?.data?.response?.autoTime < 5 &&
                                  " suspended-box"}`}
                              >
                                <span className="casino-odds ng-tns-c3502172659-2">
                                  {getOddForEntity("Win with 16", casinoData)}
                                </span>
                              </div>
                              <div className="casino-nation-book text-center w-100 ng-tns-c3502172659-2">
                                0{" "}
                              </div>
                            </div>
                            <div className="casino-odd-box-container ng-tns-c3502172659-2 ng-star-inserted">
                              <div className="casino-nation-name ng-tns-c3502172659-2">
                                Win with 17{" "}
                                <div
                                  onClick={() =>
                                    setBet("Win with 17", casinoData, dispatch)
                                  }
                                  className="min_max_collapse ms-2 ng-tns-c3502172659-2"
                                >
                                  <a
                                    data-bs-toggle="collapse"
                                    role="button"
                                    aria-expanded="false"
                                    className="minmax-btn collapsed ng-tns-c3502172659-2"
                                    href="#collapserange2race2012"
                                    aria-controls="collapserange2race2012"
                                  >
                                    <i className="fas fa-info-circle ng-tns-c3502172659-2" />
                                  </a>
                                  <div
                                    className="range-collpase collapse ng-tns-c3502172659-2"
                                    id="collapserange2race2012"
                                  >
                                    <span className="min_max_span ms-0 ng-tns-c3502172659-2">
                                      <b className="ng-tns-c3502172659-2">
                                        Min:
                                      </b>
                                      <span className="ng-tns-c3502172659-2">
                                        {getOddForEntity(
                                          "Win with 17",
                                          casinoData
                                        )}
                                      </span>
                                    </span>
                                    <span className="min_max_span ms-1 ng-tns-c3502172659-2">
                                      <b className="ng-tns-c3502172659-2">
                                        Max:
                                      </b>
                                      <span className="ng-tns-c3502172659-2">
                                        <span className="ng-tns-c3502172659-2 ng-star-inserted">
                                          25000{" "}
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`ng-tns-c3502172659-2 back casino-odds-box ${casinoData
                                  ?.data?.response?.autoTime < 5 &&
                                  " suspended-box"}`}
                              >
                                <span className="casino-odds ng-tns-c3502172659-2">
                                  {getOddForEntity(
                                    "Win with 17",
                                    casinoData,
                                    false
                                  )}
                                </span>
                              </div>
                              <div className="casino-nation-book text-center w-100 ng-tns-c3502172659-2">
                                0{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <marquee className="ng-tns-c3502172659-2">
                        <p className="text-danger ng-tns-c3502172659-2">
                          <b className="ng-tns-c3502172659-2"> Hi </b>
                        </p>
                      </marquee>
                    </div>

                    <div className="ng-tns-c3502172659-2">
                      <div className="casino-last-result-title ng-tns-c3502172659-2">
                        <span className="ng-tns-c3502172659-2">
                          Last Result
                        </span>
                        <span className="ng-tns-c3502172659-2">
                          <a className="ng-tns-c3502172659-2">View All</a>
                        </span>
                      </div>
                      <div className="casino-last-results ng-tns-c3502172659-2 ng-star-inserted">
                       {lastTenResultData?.data?.data?.casinoMarketHistoryResponseDTOS?.map(item=> <span className="ng-tns-c3502172659-2 result ng-star-inserted">
                          <img
                            alt=""
                            className="ng-tns-c3502172659-2"
                            src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/2d/${item?.mainWinner}.webp`}
                          />
                        </span>)}
                       
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <PlacedBet
                  bets={casinoBets?.data?.response}
                  casinoData={casinoData}
                  dispatch={dispatch}
                />
              )}

              <div className="ng-tns-c3502172659-2 d-none matchbettable w-100 ng-star-inserted">
                <div className="table-responsive ng-tns-c3502172659-2">
                  <table className="table ng-tns-c3502172659-2">
                    <thead className="ng-tns-c3502172659-2">
                      <tr className="ng-tns-c3502172659-2">
                        <th className="ng-tns-c3502172659-2">Matched Bet</th>
                        <th className="text-end ng-tns-c3502172659-2">Odds</th>
                        <th className="text-end ng-tns-c3502172659-2">Stake</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="sidebar right-sidebar casino-right-sidebar ng-tns-c3502172659-2">
            <div className="sidebar-box place-bet-container d-xl-block d-none ng-tns-c3502172659-2">
              <div className="sidebar-title ng-tns-c3502172659-2">
                <h4 className="ng-tns-c3502172659-2">Place Bet</h4>
              </div>
            </div>
            <div className="sidebar-box my-bet-container d-xl-block d-none ng-tns-c3502172659-2">
              <div className="sidebar-title ng-tns-c3502172659-2">
                <h4 className="ng-tns-c3502172659-2">My Bet</h4>
                <a className="sideviewmore ng-tns-c3502172659-2">View more</a>
              </div>
              <div className="my-bets ng-tns-c3502172659-2">
                <div className="table-responsive w-100 ng-tns-c3502172659-2">
                  <table className="table ng-tns-c3502172659-2">
                    <thead className="ng-tns-c3502172659-2">
                      <tr className="ng-tns-c3502172659-2">
                        <th className="ng-tns-c3502172659-2">Matched Bet</th>
                        <th className="text-end ng-tns-c3502172659-2">Odds</th>
                        <th className="text-end ng-tns-c3502172659-2">Stake</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
            <div className="sidebar-box sidebar-box-rules my-bet-container ng-tns-c3502172659-2">
              <app-casino-game-rules
                className="ng-tns-c3502172659-2"
                _nghost-ng-c1761710207
              />
            </div>
          </div>
        </div>
      </div>
      <PlaceBet GAME_ID={GAME_ID} />
    </app-casino-match-detail>
  );
}
